import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AppService } from "app/app.service";
import { TranslateService } from "@ngx-translate/core";
import { Ng2DeviceService } from 'ng2-device-detector';
import { Observable } from "rxjs/Observable";
import { Globals } from "app/globals";

@Component({
	selector: 'vbn-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	deviceInfo = null;
	link: String;
	hideSocialMediaButtons: boolean = false;
	cookieConsent: any;
	showConsent: boolean;

	constructor(public _router: Router,
		public route: ActivatedRoute,
		private appService: AppService,
		private translate: TranslateService,
		private deviceService: Ng2DeviceService, 
		private router: Router,
		private globals: Globals
	) {
		if (!localStorage.getItem("Consent")) {
			this.showConsent = true;
		}
		this.epicFunction();
		// if (localStorage.getItem("vbn-language") === null) {
		// 	this.translate.setDefaultLang('fr')
		// 	this.translate.use('fr')
		// 	localStorage.setItem("vbn-language", 'fr')
		// } else  if (localStorage.getItem("vbn-language") === 'en') {
		// 	this.translate.setDefaultLang('en')
		// 	this.translate.use('en')
		// 	localStorage.setItem("vbn-language", 'en')
		// } 	
		// else if (localStorage.getItem("vbn-language") === 'ro') {
		// 	this.translate.setDefaultLang('ro')
		// 	this.translate.use('ro')
		// 	localStorage.setItem("vbn-language", 'ro')
		// }

		if (localStorage.getItem("vbn-language") === null) {
			this.translate.setDefaultLang('en')
			this.translate.use('en')
			localStorage.setItem("vbn-language", 'en')
		} 
		this.translate.setDefaultLang('en')
		this.translate.use('en')
		localStorage.setItem("vbn-language", 'en')
	}

	epicFunction() {
		this.deviceInfo = this.deviceService.getDeviceInfo();
		if (this.deviceInfo.browser === "ie" && this.deviceInfo.browser_version <= "12") {
			this._router.navigate(['/browserfail'])
		}
	}

	ngOnInit() {
		window.onscroll = function () {
		  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			document.getElementById("scrollBtn").style.display = "block";
		  } else {
			document.getElementById("scrollBtn").style.display = "none";
		  }
		}
	  }
	
	  scrollToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	  }
	statusCheck(param) {
		this.link = location.hostname + "/home";
		switch (param) {
			case 't': window.open('https://twitter.com/intent/tweet?text=' + this.link);
				break;
			case 'f': window.open('https://www.facebook.com/sharer/sharer.php?u=' + this.link);
				break;
		}
	}

	accept() {
		localStorage.setItem("Consent", "Accept")
		this.cookieConsent = localStorage.getItem("Consent")
		if (this.cookieConsent == "Accept") {
			this.showConsent = false;
		}
	}
	decline() {
		localStorage.setItem("Consent", "Decline")
		this.cookieConsent = localStorage.getItem("Consent")
		if (this.cookieConsent == "Decline") {
			this.showConsent = false;
		}
	}
}
