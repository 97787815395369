import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { MessageService } from 'app/message-service.service';

@Component({
  selector: 'vbn-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  language = localStorage.getItem("vbn-language");

  subscription: Subscription;
  
  constructor(private messageService: MessageService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
			this.language = message.changedLanguage;
		});
   }

  ngOnInit() {
  }

}
