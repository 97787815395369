import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  sectionCont:any;
  columnContent:any;
  columnContent2:any;

  constructor() { }


  ngOnInit() {
    this.sectionCont = {
      title: "Products",
      description:"We solve your business challenges with robust solutions, developed by our in-house experts of by one of our renowned partners. Our senior consultants help you identify the best choice for your organization.",
      bkgImage:"assets/carlos-muza-84523-unsplash.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };

    this.columnContent ={
      title:"Proprietary Products",
      cols: [
              {
                title:"", //the title of the column
                topImg: "/assets/Artboards-LogoEasyDoCms.png",//for some columns, we have image instead of the title
                description:"A Content Management System that integrates additional services such as newsletters, e-Commerce functionalities and online customer support.",
                button:{
                  color:"#0E87FF",
                  text: "LEARN MORE >",
                  link: "products-cms"
                },
              },
              {
                title:"",
                topImg:"/assets/Artboards-LogoEasyDoAccess.png",
                description:"A platform with access control features, comprehensive auditing and other comprehensive security functions.",
                button:{
                  color:"#0E87FF",
                  text: "LEARN MORE >",
                  link:"our-company#partners-section"
                },
              },
              {
                title:"",
                topImg:"/assets/Artboards-LogoEasyDoEvents.png",
                description:"A digital platform for managing Events & Emails, with integrated services like online payments, kiosk content, lottery, seating planning and online support.",
                button:{
                  color:"#0E87FF",
                  text: "LEARN MORE >",
                  link:"our-company#certifications-section"
                },
              }
            ]
          }

    this.columnContent2 ={
      title:"Partner Products",
      cols: [
              {
                title:"", //the title of the column
                topImg: "/assets/axway_1.png",//for some columns, we have image instead of the title
                description:"A suite of partner products for API management, file transfer, content collaboration, B2B cloud integration, app development and analytics. ",
                button:{
                  color:"#0E87FF",
                  text: "LEARN MORE >",
                  link: "products-axway"
                },
              },
              {
                title:"",
                topImg:"/assets/splunk.png",
                description:"A partner product that captures, indexes, and correlates real-time data in a searchable repository from which it can generate graphs, reports, alerts, dashboards, and visualizations.",
                button:{
                  color:"#0E87FF",
                  text: "LEARN MORE >",
                  link:"products-splunk"
                },
              },
              {
                title:"",
                topImg:"/assets/sas.png",
                description:"A software suite developed by the SAS Institute, including products for Customer Intelligence, Fraud Prevention, Enterprise GRC (Governance, Risk and Compliance), Enterprise Risk, IT Management and Supply Chain Intelligence. ",
                button:{
                  color:"#0E87FF",
                  text: "LEARN MORE >",
                  link:"products-sas"
                },
              }
            ]
          }


  }

}
