import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { NewsletterResource } from 'app/models/NewsletterResource';
import { AppService } from 'app/app.service';

@Component({
  selector: 'vbn-gdpr-create-subscriber',
  templateUrl: './gdpr-create-subscriber.component.html',
  styleUrls: ['./gdpr-create-subscriber.component.scss']
})
export class GdprCreateSubscriberComponent implements OnInit {

  newsletter: NewsletterResource;
  params: Promise<void>;
  gdprError: boolean = false;
  newsletterError: boolean = false;
  email: string;

  constructor(private service: AppService, private route: ActivatedRoute, private _route: Router) {
    this.newsletter = new NewsletterResource();
   }

  ngOnInit() {
    this.params = this.route.params.forEach((params: Params) => {
      this.email = params['email']
    });
  }

  agreeGDPR() {
    if (this.newsletter.keepMyPersonalData) {
      this.gdprError = false;
    }
    else {
      this.gdprError = true;
    }
  }

  agreeNewsletter() {
    if (this.newsletter.agreeToReceiveNewsletters) {
      this.newsletterError = false;
    }
    else {
      this.newsletterError = true;
    }
  }

  createSubscriber() {
    if (this.newsletter.keepMyPersonalData) {
      this.gdprError = false;
    }
    else {
      this.gdprError = true;
      return;
    }
    if (this.newsletter.agreeToReceiveNewsletters) {
      this.newsletterError = false;
    }
    else {
      this.newsletterError = true;
      return;
    }
    this.newsletter.email = this.email;
    this.service.send('/newsletter/activateNewsletter', this.newsletter)
      .subscribe(
      (response: NewsletterResource) => {
        this._route.navigate(['/home'])
      },
      error => {
      })
  }

}
