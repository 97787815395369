import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations'

@Component({
  selector: 'vbn-slide-hompage2',
  templateUrl: './slide-hompage2.component.html',
  styleUrls: ['./slide-hompage2.component.scss'],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition("void <=> *", [animate("0.5s ease-in-out")])
  ])]
})
export class SlideHompage2Component implements OnInit {
  counter = 0;
  countern = 1;
  counterp = 4;
  slideItems = [
    { src: 'https://placeimg.com/600/600/any', title: 'Title 1', text2: "David Coyne | CEO Vauban", text: "It's fantastic to work with EasyDO Digital Technologies team. We have a true partner who cares about our short and long term goals and needs. Moreover, we received the logistical support we needed and many interesting suggestions from the EasyDO team. We appreciate the speed which they have responded to our demands, the ability to understand the complexity of our activities and the creativity which they have responded to the challenges. The Golesti Museum team recommends EasyDO Digital Technologies as a professional and trustworthy partner.Iustin DEJANUManager Golesti Museum" },
    { src: 'https://placeimg.com/600/600/nature', title: 'Title 2', text2: "David Coyne | CEO Vauban", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing" },
    { src: 'https://placeimg.com/600/600/sepia', title: 'Title 3',text2: "David Coyne | CEO Vauban", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing" },
    { src: 'https://placeimg.com/600/600/people', title: 'Title 4',text2: "David Coyne | CEO Vauban", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing" },
    { src: 'https://placeimg.com/600/600/tech', title: 'Title 5',text2: "David Coyne | CEO Vauban", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing" }
  ];
  
  constructor() {

  }

  ngOnInit() {
  }



  showNextImage() {
    if (this.counter < this.slideItems.length -1) {
      this.counter += 1;
    }else {
      this.counter = 0;
    }
    this.setCounterP();
    this.setCounterN();
  }

setCounterP(){
  if(this.counter > 0){
    this.counterp = this.counter -1;
  }else {
    this.counterp = this.slideItems.length -1;
  }  
}

setCounterN(){
  if(this.counter < this.slideItems.length-1){
    this.countern = this.counter + 1;
  }else {
    this.countern = 0;
  }
  
}

 

  showPreviousImage() {
    if (this.counter >= 1) {
      this.counter = this.counter - 1;
    }else {
      this.counter = this.slideItems.length-1;
    }
    this.setCounterP();
    this.setCounterN();
  }

  showSelected(value){
    this.counter = value;
    this.setCounterP();
    this.setCounterN();
  }

}
