import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-our-company',
  templateUrl: './our-company.component.html',
  styleUrls: ['./our-company.component.scss']
})
export class OurCompanyComponent implements OnInit {

  sectionCont:any;
  content2:any;
  columnContent:any;
  imageAndContent:any;
  imageAndContent2:any;
  content3:any;
  activeMenu: boolean[] = [true, false, false, false];
  
  constructor() { }

  ngOnInit() {
    //used when we have content and on the right/left side we have an image
    //also can add aditional content at the bottom
    this.imageAndContent = {
      imageLeft: true,
      imageRight: false,
      img:"assets/company.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"Our goal is to provide solutions and services for the European market using our delivery capacity from our Romanian service centers.",
        topContent:"Our unique combination of strengths is based on: ",
        checkMark: false,
        contentList:[
          "• An innovative and efficient offer supported by synergies with our application partners ",
          "• Specific software development by vertical ",
          "• Industrialization & automation of services with 24/7 capacity ",
          "• Potential acceleration with targeted acquisitions (customer bases or skills centers) and expansion into new territories. ",
        ],
        additionalContent:'" Our fundamental values are respect, fairness, ethics, openness and teamwork. "', //text at the bottom of this content
      }
    }
    this.imageAndContent2 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/our-comp.png",
      content:{
        title:"Why choose us ?",
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "We are the top provider of custom software solutions and services currently operating on the Romanian market.",
          "We are leaders in data governance and security. ",
          "We integrate and offer support for the widest range of 3rd party applications. ",
          "We build genuine long-term relationships based on trust, confidentiality, honesty and integrity. ",
          "Our products are well-known for being user friendly and flexible enough to adapt to our client’s evolving needs. ",
          "We have a customer-centric and one-stop-shop approach. ",
          "Our vision is based on applying cutting-edge technology to drive digital transformation across all industries."
        ],
        additionalContent:'',
      }
    }
    this.columnContent =  {
      title:"",
      cols: [
        {
          title:"Who we are",
          description:"Established in 2018 with an initial investment of + 1 M EUR",
          button:{
            color:"#0E87FF",
            text: "LEARN MORE >",
            link: "our-company#who-we-are"
          },
        },
        {
          title:"Partners",
          description:"EASYDO is the partner of world-renowned IT companies ",
          button:{
            color:"#0E87FF",
            text: "LEARN MORE >",
            link:"our-company#partners-section"
          },
        },
        {
          title:"Certifications",
          description:"Certified Quality & Information security management",
          button:{
            color:"#0E87FF",
            text: "LEARN MORE >",
            link:"our-company#certifications-section"
          },
        }
      ]
      },

    this.sectionCont = {
      title: "The success of your business depends on the quality of the partners that you choose to collaborate with.",
      description:"At EasyDo, we fully understand and deliver on our responsibility to be the most reliable and trustworthy partner to your organization",
      bkgImage:"assets/campaign-creators-1166997-unsplash.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };
/*     this.sectionCont = {
      title: "The success of your business depends on the quality of the partners that you choose to collaborate with.",
      description:"At EasyDo, we fully understand and deliver on our responsibility to be the most reliable and trustworthy partner to your organization",
      bkgImage:"assets/robot-shaking-hands.png", //used if we display a bkg image instead of gradient color bkg
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    }; */
    this.content2 = {
      header: "",
      headerImg: "assets/map.png",
      headerBKGColor1: "#fff",
      textColor:"#000",
      title: "Who we are",
      description: "EASYDO was born from a spin-off activity of Vauban, now operating as an independent company with over 60 consultants, led by senior associates and senior experts. Established in 2018 with an initial investment of + 1 M EUR, the turnover expected for 2019 is + 1.4 M EUR and expected to reach + 10 M EUR by the end of 2022.",
      imgOverlayColor:"",
      imgs:[
       /*  {
          src:"assets/laptop-3190194_640.png",
          description: ["1. "," Change"],
          textOver: true
        },
        {
          src:"assets/security-265130_640.png",
          description: ["2. ","Control"],
          textOver: true
        },
        {
          src:"assets/robot-2791671_640.png",
          description: ["3. ","Industrialization"],
          textOver: true
      } */],

      bottomTextCol:{
        backgroundColor: "#0D1626",
        topImage:"assets/hexa_small.png",
        textColor:"#ffffff",
        texts:[{
          text1:"60 + ",
          text2:"Consultants"
        },
        {
          text1:"+1 M EUR",
          text2:"Investment"
        },
        {
          text1:"+10 M EUR",
          text2:"By 2022"
        }],

      }, //no images displayed, but texts
        
      button:{}
  }
  this.content3 = {
      header: "",
      headerImg: "",
      headerBKGColor1: "",
      textColor:"",
      title: "Our local responsibility",
      description: "EASYDO contributes to economic development, while improving the quality of life of workers and their families, the local community and society at large. EASYDO implements a comprehensive corporate social responsibility policy. ",
      imgOverlayColor:"",
      imgs:[],

      bottomTextCol:{ }, //no images displayed, but texts
      button:{}
  }
  }

  activateMenu(menuIndex){
    this.activeMenu = [false,false, false, false];
    this.activeMenu[menuIndex] = true;
  }
}
