import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vbn-services-content',
  templateUrl: './services-content.component.html',
  styleUrls: ['./services-content.component.scss']
})
export class ServicesContentComponent implements OnInit {

  box_width: string;
  contentTextSafeHtml: SafeHtml;
  width_ind = 1;
  constructor(private _route: Router, private sanitizer: DomSanitizer, private translate: TranslateService) { }

  @Input()
  sectionContent

  ngOnInit() {
    if(this.sectionContent.boxWidth === "20%"){
      this.width_ind = 1;
     // this.box_width = this.sectionContent.boxWidth;
    }else{
        if(this.sectionContent.boxWidth === "40%"){
          this.width_ind = 2;
        }else{
          this.width_ind = (this.sectionContent.boxes.length === 4 ) ?2:((this.sectionContent.boxes.length === 8 )?3:4);
          //this.box_width = (this.sectionContent.boxes.length === 4 ) ?"40%":((this.sectionContent.boxes.length === 8 )?"25%":"30%");
      }
    }
  }

  getBoxDescription(text){
    return text
  }
  goToPage(link){
    if(link){
      this._route.navigate([link])
		  window.scrollTo(500, 0);
    }
  }
}
