import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { HomepageSliderListResource } from 'app/models/HomepageSliderListResource';
import { SliderHomepageService } from 'app/slider-homepage/slider-homepage.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MenuRoutes } from 'app/main-menu/menu-routes';

@Component({
  selector: 'vbn-slider-homepage',
  providers: [SliderHomepageService],
  templateUrl: './slider-homepage.component.html',
  styleUrls: ['./slider-homepage.component.scss']
})
export class SliderHomepageComponent implements OnInit {
  params: any;
  sliderHomepageList: HomepageSliderListResource;
  errorMessage: any;
  getSLiders: any;
  slideSelectedCrt: any;
  slideRight: any;
  slideLeft: any;
  timer: any;
  index: number = 0;
  slides = document.querySelectorAll(".slide");
  displayDotImage:any;

  @Input()
    sliderList: string;

  @Input()
  displayDots: boolean;

    
  @HostBinding('class') classes

  constructor(private sliderHomepageService: SliderHomepageService, private _route: Router, private route: ActivatedRoute) {
    this.sliderHomepageList = new HomepageSliderListResource()
  }

  ngOnInit() {
      //this.getHomapegeLocationList();
      this.getSLiders = this.sliderList;
      this.slideSelectedCrt = this.getSLiders[0];
      this.index = 0;
      this.slideShow();
      this.displayDotImage = this.displayDots;
      
  }

  getHomapegeLocationList() {
    this.sliderHomepageService.getSlider(`/homepage/slider/public`).subscribe(
      (body: HomepageSliderListResource) => {
        this.sliderHomepageList = body;
        if (!this.sliderHomepageList.homepageSliderList) {
          this.classes = "hideCoursesHomepage";
        }
        this.getSLiders = this.sliderHomepageList.homepageSliderList;
        if (this.getSLiders != null && this.getSLiders != undefined) {
          this.move_carusel(0);
          if(this.getSLiders.length > 1){
            this.move_carusel_right(1);
          }
          if(this.getSLiders.length > 2){
            this.move_carusel_left(this.getSLiders.length - 1);
          }
           //this.slideShow();
        }

      },
      error => this.errorMessage = <any>error)
  }

  move_carusel(index) {
    this.slideSelectedCrt = this.getSLiders[index];
    this.index = index;
  }

  move_carusel_right(index) {
    this.slideRight = this.getSLiders[index]
    this.index = index;
  }

  move_carusel_left(index) {
    this.slideLeft = this.getSLiders[index]
    this.index = index;
  }

  move_right_carusel() {
    this.stop();
    var indexCrt = this.getSLiders.indexOf(this.slideSelectedCrt);
    var indexCrtLeft = this.getSLiders.indexOf(this.slideRight);
    var indexCrtRight = this.getSLiders.indexOf(this.slideLeft);
    var sliderNo = this.getSLiders.length - 1;
    if (indexCrt < sliderNo) {
      indexCrt += 1;
      this.slideSelectedCrt = this.getSLiders[indexCrt];
    } else {
      indexCrt = 0;
      this.slideSelectedCrt = this.getSLiders[indexCrt];
    }
    if (indexCrtLeft < sliderNo) {
      indexCrtLeft += 1;
      this.slideRight = this.getSLiders[indexCrtLeft];
    } else {
      indexCrtLeft = 0;
      this.slideRight = this.getSLiders[indexCrtLeft];
    }
    if (indexCrtRight < sliderNo) {
      indexCrtRight += 1;
      this.slideLeft = this.getSLiders[indexCrtRight];
    } else {
      indexCrtRight = 0;
      this.slideLeft = this.getSLiders[indexCrtRight];
    }
  }

  move_left_carusel() {
    this.stop();
    var indexCrt = this.getSLiders.indexOf(this.slideSelectedCrt);
    var indexCrtLeft = this.getSLiders.indexOf(this.slideRight);
    var indexCrtRight = this.getSLiders.indexOf(this.slideLeft);
    var sliderNo = this.getSLiders.length - 1;
    if (indexCrt > 0) {
      indexCrt -= 1;
      this.slideSelectedCrt = this.getSLiders[indexCrt];
    } else {
      indexCrt = sliderNo;
      this.slideSelectedCrt = this.getSLiders[indexCrt];
    }
    if (indexCrtLeft > 0) {
       indexCrtLeft -= 1;
      this.slideRight = this.getSLiders[indexCrtLeft];
    } else {
      indexCrtLeft = sliderNo;
      this.slideRight = this.getSLiders[indexCrtLeft];
    }
    if (indexCrtRight > 0) {
       indexCrtRight -= 1;
      this.slideLeft = this.getSLiders[indexCrtRight];
    } else {
      indexCrtRight = sliderNo;
      this.slideLeft = this.getSLiders[indexCrtRight];
    }
    console.log(indexCrtRight, indexCrt, indexCrtLeft)
  }

  goToSlider(link) {
    var substringNews = "news";
    var substringText = "text";
    var substringEvent = "event"
    var substringMovie = "movie_details";
    var substringCinema = "cinema_homepage";
    if (link.includes(substringNews) || link.includes(substringText) || link.includes(substringEvent)
      || link.includes(substringMovie) || link.includes(substringCinema)) {
      window.location.href = link;
    } else {
      window.open(link);
      window.focus();
    }
  }

  slideShow() {
    var that = this;
    var sliderLength = that.getSLiders.length - 1;
    that.timer = setInterval(function () {
      if (that.index === sliderLength) {
        that.index = -1;
      }
      that.index++;

      that.move_carusel(that.index);

    }, 5000);
  }

  stop() {
    var that = this;
    clearInterval(that.timer);
    that.timer = null;
  }

  hoverImage(width) {
    return;
      document.getElementById('a').style.width = width + '%';
      document.getElementById('b').style.width = width + '%';
  }
}
