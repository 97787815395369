import { Directive, ElementRef, Renderer, OnChanges, SimpleChanges, Input } from '@angular/core';

@Directive({
	selector: '[address]'
})
export class NewTabDirective implements OnChanges {

	@Input() public set address(ev) {
		if (ev.indexOf("institutfrancais.ro") < 0) {
			this.renderer.setElementAttribute(this.el.nativeElement, 'target', '_blank')
			this.renderer.setElementAttribute(this.el.nativeElement, 'href', ev)
		} else {
			this.renderer.setElementAttribute(this.el.nativeElement, 'href', ev)
		}

	}

	constructor(private el: ElementRef, private renderer: Renderer) {
		// Use renderer to render the element with styles
		//renderer.setElementStyle(el.nativeElement, 'display', 'none');
	}

	ngOnChanges(changes: SimpleChanges): void {

	}
}
