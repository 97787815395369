import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { HomepageSliderListResource } from 'app/models/HomepageSliderListResource';
import { HttpClient } from '@angular/common/http';
import { environment } from "app/baseurl";

@Injectable()
export class SliderHomepageService {

  constructor(private http: HttpClient) { }
  
  getSlider(url): Observable<HomepageSliderListResource> {
		return this.http.get<HomepageSliderListResource>(environment.url + url)
	}
}
