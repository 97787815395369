import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-easydo-access',
  templateUrl: './easydo-access.component.html',
  styleUrls: ['./easydo-access.component.scss']
})
export class EasydoAccessComponent implements OnInit {

  sectionCont: any;
  sectionContent: any;
  sectionImageContent: any;
  sectionList: any;
  sectionList1: any;
  sectionList2: any;
  sectionListGuarantee: any;
  columnContent:any;
  servicesColumnContent:any;
  inovativeFeatures: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "EasyDo KeyPaaS",
      subtitle: "Digital Key, the solution to simplify your security access experience!",
      description:"Orchestrate the security and management of your building access control systems and your employees. For more information please access <a href='http://www.keypaas.com' target='_blank'>www.keypaas.com</a>",
      bkgImage:"assets/keypaas.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      additionalButtons:[ ]
    };

    this.sectionContent = {
      isDots: false,
      dotsImg: null,
      isImg: false,
      img: "",
      isVideo: false,
      link: null,
      title: "ALL IN ONE PLACE",
      titleImage:"",
      description:"Using our flexible cloud-based integrated security platform, you can digitalize the control access and management of your building and your employees.",
    }

    this.columnContent =  {
      title:"The KeyPaaS Platform offers integrated solutions for:",
      cols: [
        {
          title:"Office & Facilities",
          description:"",
          button:{
            color:"",
            text: "",
            link: ""
          },
        },
        {
          title:"Public Institutions",
          description:"",
          button:{
            color:"",
            text: "",
            link:""
          },
        },
        {
          title:"Residential Homes",
          description:"",
          button:{
            color:"",
            text: "",
            link:""
          },
        },
        {
          title:"Rental Homes",
          description:"",
          button:{
            color:"",
            text: "",
            link:""
          },
        },
        {
          title:"Hotels & Pensions",
          description:"",
          button:{
            color:"",
            text: "",
            link:""
          },
        }
      ]
      },
    this.sectionImageContent = {
      imageLeft: false,
      imageRight: true,
      img:"assets/feed2.png",  //the image from -left side
      content:{   //text content from right side
        title:"KEYPAAS - CORE FEATURES", 
        subtitle:"",
        topContent:`The access control platform offers you a perfectly flexible system for all types of businesses – low to large sized projects.  Thanks to the scalability of the 
        hardware components – Access Modular Controllers, readers and credentials – the system grows according to your security needs.`,
        checkMark: true,
        contentList:[
          "Employee Management: Employee’s and vehicles’ physical access monitoring within premises",
          "Access Management: Selective physical access, based on each employee’s group membership / department",
          "Certification Management: Employees’ certificate management module, integrated with physical access functionality",
          "Visitors Management: Creating traceable access badges for incoming visitors",
          "Reports Management: Manage, generate reports with time and attendance",
          "Mobile Management: Easy access and management of company security and employees from the mobile devices from any location",
          "Real time physical location tracking",
          "Platform integration with hardware and IOT Devices (e.g. tourniquets)",
          "E-mail notifications & SMS alerts flow",
          "GDPR compliant",
          "Permanent availability via web application",
          "Comprehensive security platform with an easy to use interface"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.sectionList = {
      title: "Your benefits from using the EasyDo Secure Access platform:",
      checkMark: true,
      contentList: [
        "Provide a safe environment for employees, visitors and contractors",
        "Be compliant with health and data regulations",
        "Offer a welcoming experience at the front door or front desk to your visitors or clients",
        "Know who can access your facility, manage their level of access and meet regulatory compliance objectives",
        "Control employee absenteeism",
        "Gain a 360-degrees view over employees and visitors’ actions",
        "Reduce the costs and risks of managing physical access of employees, contractors and visitors",
        "Improve front-desk and security officer efficiency by automating visitor check-in processes, offloading visitor pre-registration to end users",
        "Elevate brand experience by using personalized branding on QR codes",
        "Facilitate better management of visitors by electronically maintaining visit history and access logs"
      ]
    }

    this.sectionList1 = {
      title: "What you are facing today",
      checkMark: true,
      contentList: [
        "High risk of Security Breaches caused by employees and visitors",
        "No extra security for areas with sensitive data, expensive equipment or high hazard",
        "Poor control of access - No recorded data about the persons accessing the perimeter:  who, how much time, where and why",
        "Risk of blocking personnel inside the perimeter",
        "No overview of employee’s activity and worked hours",
        "Outdated control access hardware and software",
        "Manual or semi-automated operational processes, which reflect into low efficiency"
      ]
    }

    this.sectionList2 = {
      title: "What we can do for you",
      checkMark: true,
      contentList: [
        "Provide a safe environment for employees, visitors and contractors",
        "Be compliant with health and data regulations",
        "Offer a welcoming experience at the front door or front desk to your visitors or clients",
        "Gain a 360-degrees view over employees and visitors’ actions",
        "Facilitate better management of visitors by electronically maintaining visit history and access logs",
        "Continuous monitoring",
        "API with other applications (ERP, partners, IoT)",
        "Improve front-desk and security officer efficiency by automating visitor check-in processes, offloading visitor pre-registration to end users",
        "Know who can access your facility, manage their level of access and meet regulatory compliance objectives",
        "Reduce the costs and risks of managing physical access of employees, contractors and visitors",
        "Elevate brand experience by using personalized branding on QR codes"
      ]
    }

    this.sectionListGuarantee = {
      title: "Guarantee of 100% available service (24X7)",
      checkMark: true,
      contentList: [
       "Your employee or visitor forgot or lost their credentials?",
       "Our system through a complete but easy authentication process, will validate and give access to office or facility. So, an SMS and an email are sent automatically to inform about the situation.",
       "We are available to solve the request by phone or email 24X7.",
       "We can even reactivate the key via the personal assistant at entrance or API with existing market solution."
      ]
    }

    this.servicesColumnContent = {
      title: "",
      description: "",
      backgroundColor:"",
      textColor:"",
      boxexGridColor:"#ece9e9",
      boxesGridWidth:"2px",
      fullWidth: true,
      boxWidth:'20%',
      boxes: [
        {
          img: "assets/11. piechart.png",
          title: "Access Control",
          description: "Digital orchestrate and automate your access control system and reporting in real time, the access monitoring and data analytics."
        },
        {
          img: "assets/8. smartphone.png",
          title: "Mobile Management",
          description: "Easily access and manage from your mobile phone, from any location that you might be your company security and employees."
        },
       {
          img: "assets/12. users2.png",
          title: "Visitor Management",
          description: "Make your guests feel welcomed and secured by enhancing their experience with a smart control system that stands out."
        },
        {
          img: "assets/9. surveiilance.png",
          title: "Video Surveillance",
          description: "Keep real time tracking of incidents by easily accessing the video surveillance system."
        },
        {
          img: "assets/10. id-badge.png",
          title: "Identity Management",
          description: "You can use the identity management option in order to update user information across all your systems and applications."
        },
        {
          img: "assets/5. elevator.png",
          title: "Elevator Control",
          description: "Manage who can access different location by using elevator"
        },
        {
          img: "assets/9.1 eye2.png",
          title: "Managed Services",
          description: "We can make it simple and easier for you, so we can orchestrate your physical access monitoring with our intelligent security operating center."
        },
        {
          img: "assets/6. iot.png",
          title: "IOT Integration",
          description: "We can use innovative IOT Devices to improve your access control experience and to assure full integration within flows and processes."
        }
      ]
    }

    this.inovativeFeatures = {
      title: "KEYPAAS - INNOVATIVE PRODUCT FEATURES",
      description: "Innovations are the driving force behind dynamic economic growth. EasyDo is continuously looking ahead in order to identify customers' needs and developing new solutions. This way you get the technical edge they need to be successful on the market.",
      backgroundColor:"",
      noShadow4Container:true,
      textColor:"",
      boxexGridColor:"#ece9e9",
      boxesGridWidth:"2px",
      fullWidth: true,
      fullBorder:true,
      bigContentWidth:true,
      boxWidth:'40%',
      boxTextColor:"#000",
      boxes: [
        {
          img: "assets/30. slider-custom.png",
          title: "ADJUSTED TO YOUR NEEDS AND EASY TO OPERATE",
          description: `Our platform offers no limits to your agenda, access groups, controlled perimeters, business partitions or the number of users.
           Designating access privileges along with start and expiry dates is extremely simple with KeyPaaS web-based interface. <br>Deploy KeyPaaS in the following scenarios: 
           <br><b>On dedicated servers / On a ‘virtualized’ server environment / Within a private cloud / Within a hosted or third-party cloud </b>`
        },
        {
          img: "assets/31. update.png",
          title: "REAL TIME CHANGE PRIVILEGES BASED ON THREAT LEVEL",
          description: "A change in threat level will instantly cause a local or global change of access privileges. Administrators can apply rules to specific events to ensure that access to specific areas is monitored and restricted."
        },
       {
          img: "assets/cogwheel-svgrepo-com-4.svg",
          title: "LOGICAL STATEMENTS APPLIED TO INPUTS",
          description: "It is easy to set-up sophisticated logic to control output events. Multiple inputs, such as door sensors or emergency buttons, can be programmed to automatically create outputs, such as alarms or activate third party devices."
        },
        {
          img: "assets/33. iot.png",
          title: "IOT DEVICES INTEGRATION",
          description: "Our platform integrates IOT innovative devices to better manage your users experience and improve your business flows."
        },
        {
          img: "assets/32. surveillance2.png",
          title: "VIDEO INTEGRATION",
          description: "KeyPaaS offers easy video integration with access control to monitor both physical access control and surveillance video from a single point of administration, as well as link specific access control events such as forced entries, invalid presented credentials, and door held open events to live video. KeyPaaS supports integration with several leading VMS platforms to offer the best solution for the customer."
        }
      ]
    }
  }

}
