import { Component, OnInit } from '@angular/core';
import { AppService } from "app/app.service";
import { Router } from "@angular/router";

@Component({
  selector: 'vbn-case-study-golesti',
  templateUrl: './case-study-golesti.component.html',
  styleUrls: ['./case-study-golesti.component.scss']
})
export class CaseStudyGolestiComponent implements OnInit {

  sectionCont: any;
  imageAndContent: any;
  userEmail: any;
  errorEmail: boolean = false;

  constructor(private service: AppService, private route: Router) { }

  ngOnInit() {
    this.sectionCont = {
      title: "Golesti Museum - Case Study",
      description:"Muzeul Golesti, Arges, is the third largest museum in Romania and the first ethnographic museum dedicated to traditional agriculture in Romania. The central part of the museum is the old Golesti Mansion. Their activities are mainly oriented towards families with children (workshops, playground, etc.)",
      bkgImage:"assets/antenna-502696-unsplash.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };

    this.imageAndContent = {
      imageLeft: false,
      imageRight: true,
      img:"assets/Group 217.png",  //the image from -left side
      content:{   //text content from right side
        title:"Client’s Benefits from working with us:", 
        subtitle:"",
        topContent:"",
        checkMark: false,
        contentList:[
          "Complete ecosystem with the right the digital solutions and a one-stop-shop approach",
          "Increasing the visitors’ numbers, engagement and loyalty ",
          "Creating brand awareness",
          "A more exciting, interactive and informative experience for the visitors (digital solutions to present historical context, to explain or demonstrate how various ingenious systems worked, etc.)",
          "Better quality of services such as payment systems and secure access, which translated into cost & time savings",
          "Streamlining the communication: museum visitors can enjoy multiple ways to find useful information, including through video content & social media push",
          "Reports and analytics to understand visitors’ behavior & identify ways to improve their experience"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
  }

  sendContact() {
    this.errorEmail = false;
    // this.errorGdpr = false;
    // if(!this.contactUs.keepMyPersonalData) {
    //   this.errorGdpr = true;
    //   return;
    // }
    let correctEmail;
      let checkEmail;
      if (this.userEmail != undefined) {
        if (this.userEmail != null) {
          checkEmail = this.userEmail.indexOf('@');
          if (this.userEmail.includes('.', checkEmail)) {
            correctEmail = true;
          }
          else {
            correctEmail = false;
            this.errorEmail = true;
            return;
          }
        }
      } else {
        this.errorEmail = true;
        return;
      }
    this.service.pdf(`/pdf/sendGolestiPdf?email=` + this.userEmail)
      .subscribe(
      body => {
        this.userEmail = null;
        this.errorEmail = false;
        // this.errorGdpr = false;
        this.route.navigate([`/confirmation`]);
        window.scrollTo(500, 0);
      },
      error => {
        console.error(error)
      })

  }

}
