// export const environment = {
//     url: "https://gw.easydo247.com/cms/core",
//     urlPs: "https://gw.easydo247.com/cms/ps"
//   };

//  export const environment = {
//    url: "http://localhost:4400/public",
//    urlPs: "http://localhost:4400/public"
//  };

//   export const environment = {
//     url: "http://192.168.100.119/public",
//    urlPs: "http://192.168.100.119/public"
//  };

export const environment = {
  url: "/public",
  urlPs: "/public"
};