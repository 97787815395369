import { Component, OnInit } from '@angular/core';
import { GdprAgreementService } from "app/gdpr-agreement/gdpr-agreement.service";
import { GDPRAgreementResource } from "app/models/GDPRAgreementResource";
import { Params, ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'vbn-gdpr-agreement',
  templateUrl: './gdpr-agreement.component.html',
  styleUrls: ['./gdpr-agreement.component.scss'],
  providers: [GdprAgreementService],
})
export class GdprAgreementComponent implements OnInit {

  agree: boolean = false;
  notAgree: boolean = false;
  agreeNewsletter: boolean = false;
  gdprAgreement: GDPRAgreementResource;
  params: Promise<void>;
  email: string;
  hide: boolean = true;
  errorSend = false;


  constructor(private gdprAgreementService: GdprAgreementService, private route: ActivatedRoute, private _route: Router) {
    this.gdprAgreement = new GDPRAgreementResource();
  }

  ngOnInit() {
    this.params = this.route.params.forEach((params: Params) => {
      this.email = params['email']
    });
    //this.getGDPREmail(this.email);


  }

  // getGDPREmail(email) {
  //   this.gdprAgreementService.getGDPREmailDetails('/newsletter/getByEmail?email=' + email)
  //     .subscribe(
  //     (response: NewsletterResource) => {
  //       if (response.rid != null) {
  //         this.hide = false;
  //       }
  //       else {
  //         this.hide = true
  //       }
  //     },
  //     error => {

  //     })
  // }

  agreeToGDPR(agreement) {
    this.errorSend = false;
    this.gdprAgreement.keepPersonalData = agreement;
    if (agreement) {
      if (this.notAgree) {
        this.notAgree = false;
      }
    }
  }

  newsletterAgreement(agreement) {
    this.errorSend = false;
    this.gdprAgreement.sendNewsletter = agreement;
    if (agreement) {
      if (this.notAgree) {
        this.notAgree = false;
      }
    }

  }

  notAgreeToGDPR(agreement) {
    this.errorSend = false;
    if (agreement) {
      if (this.agree) {
        this.agree = false;
        this.gdprAgreement.keepPersonalData = false;
      }
      if (this.agreeNewsletter) {
        this.agreeNewsletter = false;
        this.gdprAgreement.sendNewsletter = false;
      }
    }
  }



  sendGDPEAgreement() {

    this.gdprAgreement.email = this.email;
    this.errorSend = false
    if (this.agree == true || this.notAgree) {

      this.gdprAgreementService.postGDPR('/user/gdpr/user', this.gdprAgreement)
        .subscribe(
        (response: GDPRAgreementResource) => {
          this._route.navigate(['/home'])
        },
        error => {

        })
    }
    else {
      this.errorSend = true;
    }
  }


}
