import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "app/baseurl";
import { HomepageContentList } from 'app/models/HomepageContentList';


@Injectable()
export class ContentTextService {

	constructor(private http: HttpClient) { }

	getContent(url): Observable<HomepageContentList> {
		return this.http.get<HomepageContentList>(environment.url + url);
	}
}
