import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  sectionCont:any;
  lat: number = 51.678418;
  lng: number = 7.809007;
  isOverlay:string = "block";
  constructor() { }

  ngOnInit() {
    
    
    this.sectionCont = {
      title: "Contact us",
      description:"We’d love to help your business grow by making the most of the latest technologies! Let’s have a chat!",
      bkgImage:"assets/businessman-2956974_1920.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };
  }

  activateTheMap(val){
    this.isOverlay = val?"block":"none";
  }

}
