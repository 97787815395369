import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-soc-services',
  templateUrl: './soc-services.component.html',
  styleUrls: ['./soc-services.component.scss']
})
export class SocServicesComponent implements OnInit {
  sectionCont:any;
  servicesColumnContent:any;
  imageAndContent:any;
  imageAndContent2:any;
  sectionContent:any;

  constructor() { }

  ngOnInit() {

    this.sectionCont = {
      title: "Security Operations Center ( SOC )",
      description:"Our Security Operations Center ( SOC ) takes the weight of ICT security concerns off your shoulders. We enable you to prevent, detect and respond to advanced cyber threats, while focusing full-time on your core activity and entrusting our experienced security experts with guarding your data and ICT Infrastructure.",
      bkgImage:"assets/soc.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };

    this.servicesColumnContent = {
      title: "Our Managed SOC Services include",
      description: "",
      backgroundColor:"#14181E",
      textColor:"#C2C2C2",
      boxexGridColor:"#fff",
      boxesGridWidth:"10px",
      fullWidth: true,
      boxes: [

        {
          img: "assets/notes-svgrepo-com (1).svg",
          title: "Log management and monitoring",
          description: "We collect and monitor logs 24/7, to enable early threat detection and meet regulatory and forensics requirements, based on Splunk Enterprise solutions. "
        },
        {
          img: "assets/bugs-search-svgrepo-com.svg",
          title: "Threat intelligence",
          description: "We combine security analytics with contextual analysis powered by Splunk Enterprise Security Application. Our team aggregates and correlates thousands of security events using a multi-tenanted security information and event management environment."
        },
       {
          img: "assets/alarm-svgrepo-com.svg",
          title: "Incident response service",
          description: "A remote-access incident responder will work with your IT and business team to help contain and mitigate threats with complete damage assessment and response planning. "
        },
        {
          img: "assets/bug-malware-svgrepo-com.svg",
          title: "Malware analysis and forensics",
          description: "During critical investigations, you can choose to engage with our digital forensics lab that supports malware analysis for unknown or zero-day malware. "
        },
        {
          img: "assets/report-svgrepo-com.svg",
          title: "Service management and reporting",
          description: "Our ServiceDesk solution can be used to report incidents and raise service requests, and our SOC analysts will work with you to ensure quick issue resolution."
        },
        {
          img: "assets/content-management-svgrepo-com.svg",
          title: "Extensive vulnerability management",
          description: "We use a cyclic approach (monitor–assess–prioritize–prevent–fix) for thorough vulnerability management. Integration with vulnerability assessment scanner feeds helps generate timely alerts. "
        }
      ]
    }

    this.imageAndContent = {
      imageLeft: false,
      imageRight: true,
      img:"assets/Datamining_2.png",  //the image from -left side
      content:{   //text content from right side
        title:"EasyDo SOC 24/7", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "Around the clock monitoring 24 / 7 x 365",
          "An experienced SOC team onboard, ensuring services up to level 3",
          "Learning experiences for the client. Our experienced team is not just a tool for your organization, it also provides opportunities for internal learning",
          "Consultancy. After an analysis of your environment, we`ll come up with the best solution for monitoring",
          "Comprehensive dashboards. View your organization`s security reports in real time",
          "Reduced costs and complexities"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.imageAndContent2 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/stephen-dawson-670638-unsplash (1).png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"",
        topContent:"",
        checkMark: false,
        contentList:[
          "A Security Operations Center (SOC) is a facility and team of cybersecurity experts responsible for monitoring and improving your organizations security performance. The team prevents and responds to data breaches and other ICT security threats with the aid of both technology and well-defined processes and procedures. We offer you a set of complementary solutions that are modular, scalable and designed to give you the ability to prevent, detect and respond to advanced threats, along with experienced security experts to help you mitigate risks and ensure an efficient management of your ICT vulnerabilities. ",
          "We combine real-time threat intelligence services and data processing to generate an in-depth view of security issues. Our security operations analysts interpret data and triage devices to scope out real security threats and advise on steps to mitigate them and help you effectively respond to incidents. Why choose our SOC Services? We cover all requested services with specific resources, with expertise and experience on providing SOC services, 24x7, Tier 1 & 2, by using Splunk Enterprise and Splunk Enterprise Security Application."
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

 /*    this.sectionContent={
      isDots:"true",
      dotsImg:"assets/dots.png",
      img: "assets/soc_2.png",
      title: "",
      description:"",
  } */
  this.sectionContent={
    isDots: true,
    dotsImg:"assets/dots.png",
    isImg: true,
    img: "assets/soc_2.png",
    isVideo: false,
    link: "",
    title: "",
    description:"",
}

  }

}
