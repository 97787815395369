import { Component, OnInit } from '@angular/core';
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { environment } from "environments/environment";
import { ValidatorFn } from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "app/message-service.service";
import { AppService } from "app/app.service";
import { Subscription } from "rxjs";
import { Validators } from "@angular/forms";
import { NewsletterResource } from "app/models/NewsletterResource";

@Component({
  selector: 'vbn-footer-newsletter',
  templateUrl: './footer-newsletter.component.html',
  styleUrls: ['./footer-newsletter.component.scss']
})
export class FooterNewsletterComponent implements OnInit {

 ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  subscription: Subscription;
  saveErrors: boolean = false;
  saveErrorsHallLogedIn: boolean = false;
  errorMessage: string;
  newsletterResource: NewsletterResource;
  newsletterPopup: boolean;
  newsletterPopupSucces: boolean;
  showError: boolean = false;
  changedLanguage: boolean = false;
  currentLanguage = localStorage.getItem("vbn-language");
  gdprError: boolean = false;
  receiveError: boolean = false;

  constructor(private service: AppService,
    private fb: FormBuilder,
    private messageService: MessageService) {
    this.newsletterResource = new NewsletterResource();
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.changedLanguage = message.changedLanguage;
      if (message.changedLanguage) {
        // this.changeLangOnTags();
      }
    });
  }

  emailValidateWithDot(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let correctEmail;
      let checkEmail;
      if (this.newsletterResource != undefined) {
        if (this.newsletterResource.email != null) {
          checkEmail = this.newsletterResource.email.indexOf('@');
          if (this.newsletterResource.email.includes('.', checkEmail)) {
            correctEmail = true;
          }
          else {
            correctEmail = false;
          }
          return correctEmail ? null : { 'correctEmail': 'email incorect' }
        }
      }
    };
  }

  addFormNewsletter = this.fb.group({
    emailNewsletter: ["", [Validators.required, Validators.email, this.emailValidateWithDot()]],
    //tags: ["", [Validators.nullValidator]],
    gdpr: ['', [Validators.nullValidator]],
    receiveNewsletter: ['', [Validators.nullValidator]]
  })


  ngOnInit() {
  }
  

  subscribe = function () {
    this.saveErrors = true;
    if (!this.addFormNewsletter.valid) {
      return
    }
    let semafor = false;
    if (this.newsletterResource.keepMyPersonalData) {
      this.gdprError = false;
    } else {
      this.gdprError = true;
      semafor = true;
    }
    if (semafor) {
      return;
    }
    this.newsletterResource.agreeToReceiveNewsletters = true;
    this.service.send(`/newsletter/addOrEdit`, this.newsletterResource)
      .subscribe(
      body => {
        this.newsletterResource = body
        this.addFormNewsletter.reset();
        if (this.newsletterResource.errorCode === 0) {
          this.newsletterPopup = true;
          this.newsletterPopupSucces = true;
          this.saveErrors = false;
        } else {
          this.newsletterPopup = true;
          this.newsletterPopupSucces = false;
        }
      },
      error => console.error(error))
  }

  @ViewChild('modalOverlay') modalOverlay: ElementRef;
  clickOutside(event: Event) {
    const target = event.target || event.srcElement;
    if ("isClickOutside") {
      this.newsletterPopup = false;
    }
  }

  scrollTop(ev) {
    console.log("----", ev)
    ev.srcElement.offsetParent.lastElementChild.lastElementChild.lastElementChild.firstElementChild.scrollTop = 0
    return ev.srcElement.offsetParent.lastElementChild.lastElementChild.lastElementChild.firstElementChild.scrollTop;
  }

}
