import { MenuButtonResource } from "app/models/MenuButtonResource";
import { TranslateJsPipe } from "app/translate-js.pipe";

export class MenuRoutes {
	static fetch(item: MenuButtonResource) {
		if (item.nodeItemResource.contentTemplate.templatePublicPath == "home") {
			return ['/home', null, null, null]
		} else if (item.nodeItemResource.contentTemplate.templatePublicPath == "contentText") {
			return ['/text', item.nodeItemResource.rid, new TranslateJsPipe().transform([item.nameRO.replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'"<>,.\/? ])+/g,'_'), item.nameFR.replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'"<>,.\/? ])+/g,'_'),item.nameEN.replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'"<>,.\/? ])+/g,'_')]), item.rid]
		}
		else {
			return ['/home', null, null, null]
		}
	}
}
