import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-products-events',
  templateUrl: './products-events.component.html',
  styleUrls: ['./products-events.component.scss']
})
export class ProductsEventsComponent implements OnInit {

  sectionCont: any;
  sectionContent: any;
  productsColumnContent: any;
  sectionList: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "EasyDo Events",
      description: "EasyDo Events is a professional enterprise application, a comprehensive single solution composed of several mobile and web applications for managing all of the functionalities that you need for a successful event.",
      bkgImage: "assets/easy2.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor: "#14181E",
      image: "assets/dots.png",
      bkgColor1: "#2F3D56",
      bkgColor2: "#070A0E",
      bkgGradient: true,
      button: {},
      subtitle: "",
      additionalButtons: []
    };
    this.sectionContent = {
      isDots: false,
      dotsImg: null,
      isImg: false,
      img: "",
      isVideo: false,
      link: null,
      title: "",
      titleImage: "assets/EasyDoEvents.png",
      description: "It integrates services like online payments, kiosk content, lottery, seating planning and tables’ organization, QR Code registration, online client’s support and many other features. It’s never been easier and faster to create and manage an event! ",
    }

    this.productsColumnContent = {
      title: "Features & Functionalities.",
      description: "Used by many high-profile corporations and business associations Easy to use, with many configurable settings to perfectly suit your needs A comprehensive, collaborative, and cost-effective Event Management Platform 100% customizable for every type of event and every type of device: tablets, kiosks and mobile phones. ",
      backgroundColor:"#fff",
      textColor:"#000",
      boxexGridColor:"#fff",
      boxesGridWidth:"0px",
      fullWidth: true,
      boxes: [
        {
          img: "assets/guest.svg",
          title: "Guest Management",
          description: ""
        },
        {
          img: "assets/invitation.svg",
          title: "Customizable Invitation",
          description: ""
        },
       {
          img: "assets/website.svg",
          title: "Customizable Website",
          description: ""
        },
        {
          img: "assets/seat.svg",
          title: "Seat Management",
          description: ""
        },
        {
          img: "assets/security.svg",
          title: "Clear improvement in security",
          description: ""
        },
        {
          img: "assets/analytics.svg",
          title: "Events Analytics",
          description: ""
        },
        {
          img: "assets/be.svg",
          title: "BE CSR Compliant",
          description: ""
        },
        {
          img: "assets/decrease.svg",
          title: "Decrease Logistic Issues",
          description: ""
        }
      ]
    }

    
    this.sectionList = {
      title: "Your benefits from using EasyDo events:",
      checkMark: true,
      contentList: [
        "Make your events successful and more efficient",
        "Simplify and optimize your operational workflow",
        "Increase you KPIs - number of guests, generated revenues, brand awareness and customer satisfaction",
        "Reduce waiting times at entrance",
        "Secure your access points and organize your attendance teams."
      ]
    }



  }



}
