import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AppService } from "app/app.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl, SafeHtml } from '@angular/platform-browser';
// import { ContentCommonFileListResource } from '../models/ContentCommonFileListResource';
import { ContentTextResource } from 'app/models/ContentTextResource';

@Component({
  selector: 'vbn-content-text',
  templateUrl: './content-text.component.html',
  styleUrls: ['./content-text.component.scss']
})
export class ContentTextComponent implements OnInit {

  errorMessage: any;
	id: number;
	content_text: ContentTextResource;
	htmlBodyRO: SafeHtml;
	htmlBodyEN: SafeHtml;
	htmlBodyFR: SafeHtml;
	params: any
	pdf: any;
	document: any;
	hasDocuments: boolean = false;

	constructor(private _router: Router,
		private route: ActivatedRoute,
		private appService: AppService,
		private sanitizer: DomSanitizer) {
		this.content_text = new ContentTextResource();
	}

	ngOnInit() {
		this.params = this.route.params.forEach((params: Params) => {
			this.id = parseInt(params['id']);
			this.getContent();
			// this.getDocuments();
		});
	}

	getContent() {
		this.appService.getContent(`/text/get?nodeId=${this.id}`).subscribe(
			body => {
				this.content_text = Object.assign({}, body)
				this.htmlBodyRO = this.sanitizer.bypassSecurityTrustHtml(this.content_text.textRO)
				this.htmlBodyEN = this.sanitizer.bypassSecurityTrustHtml(this.content_text.textEN)
				this.htmlBodyFR = this.sanitizer.bypassSecurityTrustHtml(this.content_text.textFR)
			},
			error => this.errorMessage = <any>error)
	}

	ngOnDestroy() {
	}

	// getDocuments() {
	// 	this.appService.getDocument(`/node/getContentCommonFiles?nodeId=${this.id}`).subscribe(
	// 		body => {
	// 			this.document = body
	// 			if (this.document.contentCommonFileList != null) {
	// 				if (this.document.contentCommonFileList.length > 0) {
	// 					this.hasDocuments = true;
	// 				}
	// 			}
	// 		},
	// 		error => console.error(error))
	// }

}
