import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { GDPRAgreementResource } from "app/models/GDPRAgreementResource";
import { environment } from "app/baseurl";

@Injectable({
  providedIn: 'root'
})
export class GdprAgreementService {

  constructor(private http: HttpClient) {
    
   }

   postGDPR(url,body): Observable<GDPRAgreementResource> {
    return this.http.post<GDPRAgreementResource>(environment.url+url,body);
  }

  // getGDPREmailDetails(url): Observable<NewsletterResource> {
  //   return this.http.get<NewsletterResource>(environment.url+url);
  // }
}
