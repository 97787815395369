import { Component, OnInit, HostListener, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MenuRoutes } from "app/main-menu/menu-routes";
import { MessageService } from "app/message-service.service";
import { MainMenuService } from "app/main-menu/main-menu.service";
import { MenuButtonListResource } from "app/models/MenuButtonListResource";
import { MenuButtonResource } from "app/models/MenuButtonResource";


@Component({
	selector: 'vbn-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

	menuIsFixed: boolean;
	rootMenu: MenuButtonResource[];
	currentLanguage = localStorage.getItem("vbn-language");
	changedLanguage: boolean = true;
	showSubmenu: boolean = false;
	showSubmenuServices:boolean = false;
	showSubmenuProducts:boolean = false;
	showMenuResponsive: boolean = false;
	showSubmenuStories: boolean = false;


	constructor(private mainMenuService: MainMenuService, private _route: Router, private translate: TranslateService, private messageService: MessageService) {
		this.menuIsFixed = false;
	}

	ngOnInit() {
		// this.getMenu()
	}
	// getMenu() {
	// 	this.mainMenuService.getMenu(`/menu/publicMain/get`).subscribe(
	// 		(menu_data: MenuButtonListResource) => {
	// 			this.rootMenu = menu_data.buttons;
	// 			for (let i = 0; i < this.rootMenu.length; i++) {
	// 				this.rootMenu[i].treeLevel = 0;
	// 			}
	// 		},
	// 		error => console.error(<any>error))
	// }

	goToHome() {
		this._route.navigate(['/home'])
		window.scrollTo(500, 0);
		this.showMenuResponsive = false;
	}

	goToOurCompany() {
		this._route.navigate(['/our-company'])
		window.scrollTo(500, 0);
		this.showMenuResponsive = false;
	}
	goToManagedServices() {
		this._route.navigate(['/managed-services'])
		window.scrollTo(500, 0);
		this.showSubmenuServices = false;
	}
	goToServicesSoc() {
		this._route.navigate(['/soc-service'])
		window.scrollTo(500, 0);
		this.showSubmenuServices = false;
	}

	goToServicesIntegration() {
		this._route.navigate(['/integration-service'])
		window.scrollTo(500, 0);
		this.showSubmenuServices = false;
	}

	goToServices() {
		this._route.navigate(['/services'])
		window.scrollTo(500, 0);
	}
	
	goToServicesSas() {
		this._route.navigate(['/services-saas'])
		window.scrollTo(500, 0);
		this.showSubmenuServices = false;
	}

	goToProductsSplunk() {
		this._route.navigate(['/products-splunk'])
		window.scrollTo(500, 0);
		this.showSubmenuProducts = false;
	}

	goToProductsAxway() {
		this._route.navigate(['/products-axway'])
		window.scrollTo(500, 0);
		this.showSubmenuProducts = false;
	}

	goToProductsLandingPage() {
		this._route.navigate(['/products'])
		window.scrollTo(500, 0);
	}

	goToProductsSas() {
		this._route.navigate(['/products-sas'])
		window.scrollTo(500, 0);
		this.showSubmenuProducts = false;
	}

	goToProductsCms() {
		this._route.navigate(['/products-cms'])
		window.scrollTo(500, 0);
		this.showSubmenuProducts = false;
	}

	goToProductsEvents() {
		this._route.navigate(['/products-events'])
		window.scrollTo(500, 0);
		this.showSubmenuProducts = false;
	}

	goToContactUsPage() {
		this.showSubmenu = false;
		this.showMenuResponsive = false;
		this._route.navigate(['/contact'])
		window.scrollTo(500, 0);
	}

	goToCareers() {
		this.showSubmenu = false;
		this.showMenuResponsive = false;
		this._route.navigate(['/careers']);
		window.scrollTo(500, 0);
	}

	goToSoc() {
		this._route.navigate(['/soc'])
		window.scrollTo(500, 0);
	}

	goToCustomer() {
		this._route.navigate(['/customer-case-study'])
		window.scrollTo(500, 0);
		this.showMenuResponsive = false;
	}

	goToAbout(){
		this._route.navigate(['/aboutComp'])
		window.scrollTo(500, 0);
		this.showMenuResponsive = false;
	}

	goToEasyDoAccess() {
		this._route.navigate(['/easydo-access'])
		window.scrollTo(500, 0);
		this.showSubmenuProducts = false;
	}

	goToMNAC() {
		this._route.navigate(['/case-study-mnac'])
		window.scrollTo(500, 0);
		this.showSubmenuStories = false;
	}

	goToGolesti() {
		this._route.navigate(['/case-study-golesti'])
		window.scrollTo(500, 0);
		this.showSubmenuStories = false;
	}

	goToSmartLab() {
		this._route.navigate(['/smart-lab'])
		window.scrollTo(500, 0);
		this.showMenuResponsive = false;
	}

	goToHologram() {
		this._route.navigate(['/hologram'])
		window.scrollTo(500, 0);
		this.showMenuResponsive = false;
	}

	@ViewChild('main_menu') elementView: ElementRef;

	@HostListener('window:scroll', ['$event']) onScrollEvent($event) {
		
		let tt = 100 - ((window.scrollY * 100) / 80);
		if (tt < 40) {
			this.menuIsFixed = true
		} else {
			this.menuIsFixed = false;
		}
	}

	// goToContact(ev) {
	// 	let url = this._route.url;
	// 	if (url == "/home") {
	// 		var currentEvent = document.getElementById("contact");
	// 		window.scrollTo(0, currentEvent.offsetTop);
	// 	}
	// 	else {
	// 		this._route.navigate(['/home#contact'])
	// 	}
	// }

	translateRo() {
		this.translate.setDefaultLang('ro')
		this.translate.use('ro')
		localStorage.setItem("vbn-language", 'ro')
		this.currentLanguage = localStorage.getItem("vbn-language");
		this.messageService.sendMessage({
			type: "changedLanguage",
			changedLanguage: this.currentLanguage
		});
	}


	translateEn() {
		this.translate.setDefaultLang('en')
		this.translate.use('en')
		localStorage.setItem("vbn-language", 'en')
		this.currentLanguage = localStorage.getItem("vbn-language");
		this.messageService.sendMessage({
			type: "changedLanguage",
			changedLanguage: this.currentLanguage
		});
	}

	translateFr() {
		this.translate.setDefaultLang('fr')
		this.translate.use('fr')
		localStorage.setItem("vbn-language", 'fr')
		this.currentLanguage = localStorage.getItem("vbn-language");
		this.messageService.sendMessage({
			type: "changedLanguage",
			changedLanguage: this.currentLanguage
		});
	}

	hideAllSubmenus(){
		this.hide();
		this.hideProd();
		this.hideServ();
		this.hideStories();
	}

	show(){
		this.showSubmenu = !this.showSubmenu;
		this.showSubmenuServices = false;
		this.showSubmenuProducts = false;
	}

	hide(){
		this.showSubmenu = false;
		this.showSubmenuServices = false;
		this.showSubmenuProducts = false;
	}

	showServ(){
		this.showSubmenu = false;
		this.showSubmenuServices = !this.showSubmenuServices;
		this.showSubmenuProducts = false;
		this.showSubmenuStories = false;
	}

	hideServ(){
		this.showSubmenu = false;
		this.showSubmenuServices = false;
		this.showSubmenuProducts = false;
		this.showSubmenuStories = false;
	}

	showProd(){
		this.showSubmenu = false;
		this.showSubmenuServices = false;
		this.showSubmenuProducts = !this.showSubmenuProducts;
		this.showSubmenuStories = false;
	}

	hideProd(){
		this.showSubmenu = false;
		this.showSubmenuServices = false;
		this.showSubmenuProducts = false;
		this.showSubmenuStories = false;
	}

	showStories(){
		this.showSubmenu = false;
		this.showSubmenuServices = false;
		this.showSubmenuStories = !this.showSubmenuStories;
		this.showSubmenuProducts = false;
	}

	hideStories(){
		this.showSubmenu = false;
		this.showSubmenuServices = false;
		this.showSubmenuProducts = false;
		this.showSubmenuStories = false;
	}

	insideSoc(){
		this.showSubmenu = false;
		this.showMenuResponsive = false;
	}

}
