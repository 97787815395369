import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainMenuComponent } from "app/main-menu/main-menu.component";
import { BrowserModule } from "@angular/platform-browser";
import { Title } from "@angular/platform-browser";
import { PublicPageComponent } from "app/public-page/public-page.component";
import { ConfirmationPageComponent } from "app/confirmation-page/confirmation-page.component";
import { CookiePolicyComponent } from "app/cookie-policy/cookie-policy.component";
import { GdprCommunicationComponent } from "app/gdpr-communication/gdpr-communication.component";
import { GdprAgreementComponent } from "app/gdpr-agreement/gdpr-agreement.component";
import { ContentTextComponent } from "app/content-text/content-text.component";
import { SocComponent } from './soc/soc.component';
import { CustomerCaseStudyComponent } from './customer-case-study/customer-case-study.component';
import { AboutComponent } from "app/about/about.component";
import { AboutCompComponent } from './about-comp/about-comp.component';
import { HomePageComponent } from './home-page/home-page.component';
import { OurCompanyComponent } from './our-company/our-company.component';
import { ServicesComponent } from './services/services.component';
import { ServicesSasComponent } from './services-sas/services-sas.component';
import { ManagedServicesComponent } from './managed-services/managed-services.component';
import { IntegrationServiceComponent } from "app/integration-service/integration-service.component";
import { SocServicesComponent } from './soc-services/soc-services.component';
import { Products2Component } from './products2/products2.component';
import { ProductsAxwayComponent } from "app/products-axway/products-axway.component";
import { ProductsSasComponent } from "app/products-sas/products-sas.component";
import { ProductsComponent } from './products/products.component';
import { ProductsCmsComponent } from './products-cms/products-cms.component';
import { ProductsEventsComponent } from './products-events/products-events.component';
import { EasydoAccessComponent } from "app/easydo-access/easydo-access.component";
import { ContactComponent } from './contact/contact.component';
import { CaseStudyMnacComponent } from "app/case-study-mnac/case-study-mnac.component";
import { CaseStudyGolestiComponent } from "app/case-study-golesti/case-study-golesti.component";
import { CareersComponent } from "app/careers/careers.component";
import { GdprCreateSubscriberComponent } from './gdpr-create-subscriber/gdpr-create-subscriber.component';
import { SmartLabComponent } from './smart-lab/smart-lab.component';
import { HologramComponent } from './smart-lab/hologram/hologram.component';



const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: "home"
	},
	{
		path: 'home',
		/* component: PublicPageComponent */
		component: HomePageComponent
	},
	{
		path: 'our-company',
		component: OurCompanyComponent
	},
	{
		path: 'services',
		component: ServicesComponent
	},
	{

		path: 'services-saas',
		component: ServicesSasComponent
	},
	{

		path: 'products-splunk',
		component: Products2Component
	},
	{
		path: 'managed-services',
		component: ManagedServicesComponent
	},
	{
		path: 'integration-service',
		component: IntegrationServiceComponent

	},
	{
		path: 'soc-service',
		component: SocServicesComponent

	},
	{
		path: 'products-axway',
		component: ProductsAxwayComponent

	},
	{
		path: 'products-sas',
		component: ProductsSasComponent 
	},
	{
		path: 'products',
		component: ProductsComponent

	},
	{
		path: 'products-cms',
		component: ProductsCmsComponent

	},
	{
		path: 'products-events',
		component: ProductsEventsComponent
	},
	{
		path: 'easydo-access',
		component: EasydoAccessComponent
	},
	{
		path: 'contact',
		component: ContactComponent
	},
	{
		path: 'case-study-mnac',
		component: CaseStudyMnacComponent
	},
	{
		path: 'case-study-golesti',
		component: CaseStudyGolestiComponent
	},
	{
		path: 'careers',
		component: CareersComponent
	},
	{
		path: 'confirmation',
		component: ConfirmationPageComponent
	},
	{
		path: 'cookie-policy',
		component: CookiePolicyComponent
	},
	{
		path: 'gdpr-communication',
		component: GdprCommunicationComponent
	},
	{
		path: 'soc',
		component: SocComponent
	},
	{
		path: 'about',
		component: AboutComponent
	},
	{
		path: 'aboutComp',
		component: AboutCompComponent
	},
	{
		path: 'customer-case-study',
		component: CustomerCaseStudyComponent
	},
	{
		path: 'gdpr-agreement/:email',
		component: GdprAgreementComponent
	},
	{
		path: 'text/:id/:title/:bid',
		component: ContentTextComponent
	},
	{
		path: 'gdpr-create-subscriber/:email',
		component: GdprCreateSubscriberComponent
	},
	{
		path: 'smart-lab',
		component: SmartLabComponent
	},
	{
		path: 'smart-lab/hologram',
		component: HologramComponent
	},
	{
		path: 'hologram',
		component: HologramComponent
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes), BrowserModule],
	exports: [RouterModule],
	providers: [
		Title
	]
})
export class AppRoutingModule { }
