import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { MessageService } from 'app/message-service.service';

@Component({
  selector: 'vbn-gdpr-communication',
  templateUrl: './gdpr-communication.component.html',
  styleUrls: ['./gdpr-communication.component.scss']
})
export class GdprCommunicationComponent implements OnInit {

  language = localStorage.getItem("vbn-language");
  subscription: Subscription;

   constructor(private messageService: MessageService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
			this.language = message.changedLanguage;
		});
   }

  ngOnInit() {
  }

}
