import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { MenuButtonListResource } from "app/models/MenuButtonListResource";
import { HttpClient } from "@angular/common/http";
import { environment } from "app/baseurl";
import { MenuButtonResource } from "app/models/MenuButtonResource";

@Injectable()
export class MainMenuService {

	constructor(private http: HttpClient) { }
	
	getMenu(url): Observable<MenuButtonListResource> {
		return this.http.get<MenuButtonListResource>(environment.url + url);
	}

	getSecondLineMenu(url): Observable<MenuButtonListResource>{
		return this.http.get<MenuButtonListResource>(environment.url + url);
	}

	getButton(url): Observable<MenuButtonResource>{
		return this.http.get<MenuButtonResource>(environment.url + url);
	}

}
