import { Component, OnInit, Input } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: 'vbn-section-middle-image',
	templateUrl: './section-middle-image.component.html',
	styleUrls: ['./section-middle-image.component.scss']
})
export class SectionMiddleImageComponent implements OnInit {

	description: SafeHtml;

	@Input()
	sectionContent;


	constructor(private sanitizer: DomSanitizer, ) {
	}

	ngOnInit() {
		this.description = this.sanitizer.bypassSecurityTrustHtml(this.sectionContent.description)
	}

	getLink(trailer) {
		if (trailer === undefined) {
			return;
		}
		let yt_link;
		if (trailer.match(/embed\/.{11}/i) != null) {
			yt_link = trailer.match(/embed\/.{11}/i)[0].replace('embed/', '');

		} else if (trailer.match(/watch\?v=.{11}/i) != null) {
			yt_link = trailer.match(/watch\?v=.{11}/i)[0].replace('watch?v=', '');

		} else if (trailer.match(/youtu.be\/.{11}/i) != null) {
			yt_link = trailer.match(/youtu.be\/.{11}/i)[0].replace('youtu.be/', '');

		} else {
			yt_link = trailer;
		}
		return 'https://www.youtube.com/embed/' + yt_link;
	}

}
