import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-products-axway',
  templateUrl: './products-axway.component.html',
  styleUrls: ['./products-axway.component.scss']
})
export class ProductsAxwayComponent implements OnInit {

  sectionCont: any;
  sectionContent: any;
  sectionContent2: any;
  sectionContent3: any;
  imageAndContent: any;
  imageAndContent2: any;
  imageAndContent3: any;
  imageAndContent4: any;
  imageAndContent5: any;
  imageAndContent6: any;
  sectionList: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "Axway",
      description:"As an official partner of Axway, we support your organization with Axway tools for enterprise software, enterprise application integration, business activity monitoring, business analytics, mobile application development and web API management.",
      bkgImage:"assets/kyle-sudu-36873-unsplash.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };

    // if ( localStorage.getItem("vbn-language") === "en"  ) {
    //   this.sectionContent = {
    //     isDots: false,
    //     dotsImg: null,
    //     isImg: true,
    //     img: "assets/axway_1.png",
    //     isVideo: false,
    //     link: null,
    //     title: "What ssssssssss Axway?",
    //     description:"ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss is a suite of products for API Management, Managed File Transfer, Content Collaboration, B2B Integration, App Development an Analytics.",
    //   }
    // } else if ( localStorage.getItem("vbn-language") === "ro" ) {
    //   this.sectionContent = {
    //     isDots: false,
    //     dotsImg: null,
    //     isImg: true,
    //     img: "assets/axway_1.png",
    //     isVideo: false,
    //     link: null,
    //     title: "Ce este axwei",
    //     description:"Test",
    //   }
    // }


    this.sectionContent = {
      isDots: false,
      dotsImg: null,
      isImg: true,
      img: "assets/axway_1.png",
      isVideo: false,
      link: null,
      title: "What is Axway?",
      description:"Axway is a suite of products for API Management, Managed File Transfer, Content Collaboration, B2B Integration, App Development an Analytics.",
      secImg: "assets/axway_1.png",
      secImgDesc: "Axway has been named a Leader in the new Gartner 2019 Magic Quadrant for Full Life Cycle API Management¹. We couldn’t be prouder to be trusted by our customers and recognized by Gartner. Now, we’re excited to start helping your company truly experience digital and accelerate success."
    }

    this.sectionContent3 = {
      isDots: false,
      dotsImg: null,
      isImg: true,
      img: "assets/axway_img.jpg",
      isVideo: false,
      link: null,
      title: "",
      description:"Axway has been named a Leader in the new Gartner 2019 Magic Quadrant for Full Life Cycle API Management¹. We couldn’t be prouder to be trusted by our customers and recognized by Gartner. Now, we’re excited to start helping your company truly experience digital and accelerate success.",
    }

    this.sectionContent2 = {
      isDots: false,
      dotsImg: null,
      isImg: false,
      img: null,
      isVideo: true,
      link: "https://www.youtube.com/watch?v=Dq7GwLvRhLg",
      title: "What is API Management?",
      description:"API management is the process of building and publishing APIs (Application Programming Interfaces) in a secure and scalable environment. Full lifecycle API Management will help you increase digital business from the early stages of designing and developing APIs, full circle to publishing them and analyzing how they perform.",
    }

    this.imageAndContent = {
      imageLeft: false,
      imageRight: true,
      img:"assets/lifecycle_publish_1.png",
      content:{
        title:"Build APIs", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "Accelerate building APIs that drive business value with API Builder.",
          "Code or assemble APIs with a visual point-and-click model wizard.",
          "Use pre-built connectors or a connector SDK to create custom connectors to any data source. ",
          "Use automatically generated documentation for APIs and models.",
          "View performance visualization with step-by-step execution detail for each API request."
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.imageAndContent2 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/govern_1.png",
      content:{
        title:"Govern APIs", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "Over 200 preconfigured filters simplify processing of API requests and the messages that accompany them. Configure policies to define the rules for:",
          "Managing data, including transformation from SOAP to REST",
          "Operational stability with throttling and quota mechanisms",
          "Authorization to control access",
          "Configure-not-code approach supports “dev ops” approach"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.imageAndContent3 = {
      imageLeft: false,
      imageRight: true,
      img:"assets/digital_signature_sample_policy.png",
      content:{
        title:"Secure APIs", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "Protect your business and mitigate risk with enterprise-grade API security.",
          "Preconfigured filters simplify creating security policies and protect against the OWASP Top 10 and threats yet to be discovered.",
          "Common Criteria certification enables deployment that satisfies the most demanding security and compliance requirements.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.imageAndContent4 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/Group 137.png",
      content:{
        title:"Publish APIs", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "APIs are only useful if they are available to all who might use them.",
          "Publish APIs to a catalog so they can be used globally and specify who can access them.",
          "Manage the lifecycle of API stages from registration through publishing and retirement.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.imageAndContent5 = {
      imageLeft: false,
      imageRight: true,
      img:"assets/chrome_2019-05-29_14-27-02.png",
      content:{
        title:"Promote APIs", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "Your APIs become valuable when more developers use them.",
          "Streamline registration and accelerate adoption with an API portal.",
          "Create a developer community, whether your ecosystem is internal, with partners, or extends to developers everywhere.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.imageAndContent6 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/_188292129API_Usage_-_API_Usage_details.png",
      content:{
        title:"Analyze APIs", 
        subtitle:"",
        topContent:"Measure and monitor API performance and success with IT, business, and predictive metrics.",
        checkMark: true,
        contentList:[
          "Measure the progress and success of your API program.",
          "Real-time monitoring for troubleshooting and diagnosis",
          "Predictive Insights for IT and Business operational users",
          "APIs and API Management are the foundation for modernizing your IT infrastructure. You can build, govern, secure, publish, promote and analyze your APIs, propelling your business forward with a new structure that will get you on the fast-track to digital transformation."
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }

    this.sectionList = {
      title: "Why choose EasyDo as an Integrator of Axway AMPLIFY API Management?",
      checkMark: true,
      contentList: [
        "Capacity to deliver consulting services",
        "Highly experienced local team to accompany your project through all phases, from definition to implementation, change management and roll out",
        "Architecture adapted to your needs, in order to optimize the digital transformation",
        "An experienced team with a solid background in financial services",
        "Our business and technical capabilities are enhanced by Axway’s experience, the leader in data governance",
        "Project implementation becomes easier, faster, and you gain more control over it",
        "Capacity to deliver resources: PM, Analyst, Architect, Developer, Engineer, Tester, Trainer",
        "Local support & maintenance in different modes, including 24 x 7, for the roll out"
      ]
    }

  }

}
