import { Component, OnInit } from '@angular/core';
import { AppService } from "app/app.service";
import { forkJoin } from "rxjs";
import { JobApplyResource } from "app/models/JobApplyResource";
import { Router } from "@angular/router";

@Component({
  selector: 'vbn-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  sectionCont: any;
  servicesColumnContent: any;
  isClicked: boolean = false;

  errorMessage: any;
  listJobs: ListJobs;
  formData: FormData;
  isCorrectDoc: boolean = true;
  incorrectDocSize: boolean = false;
  job: JobApplyResource;

  constructor(private service: AppService, private route: Router) {
    this.listJobs = new ListJobs();
    this.listJobs.job = new Array<Job>();
    this.job = new JobApplyResource();
    this.formData = new FormData()
  }

  ngOnInit() {

    this.service.getContent('/hipoJobs/jobs/getAll').subscribe(
			body => {
        this.listJobs.job = body.jobsListResource;
        this.listJobs.job[0].isClicked = true;
			},
      error => this.errorMessage = <any>error
    );
/* 
    this.listJobs.job = [
      {
        rid: 1,
        title: "Reporting analyst For SAS With French",
        location: "Bucharest",
        description: "Team and Role: VMware Design Expert Location: Bucharest Level: > 5 years’ experience Responsibilites: Role purpose To design and architect x86 virtualization infrastructure mainly based on VMware…",
        isClicked: false
      },
      {
        rid: 2,
        title: "Technical System Analyst",
        location: "Bucharest",
        description: "Team and Role: VMware Design Expert Location: Bucharest Level: > 5 years’ experience Responsibilites: Role purpose To design and architect x86 virtualization infrastructure mainly based on VMware…",
        isClicked: false
      },
      {
        rid: 3,
        title: "VBA Developer with French",
        location: "Bucharest",
        description: "Team and Role: VMware Design Expert Location: Bucharest Level: > 5 years’ experience Responsibilites: Role purpose To design and architect x86 virtualization infrastructure mainly based on VMware…",
        isClicked: false
      },
      {
        rid: 4,
        title: "VMware Design Expert",
        location: "Bucharest",
        description: "Team and Role: VMware Design Expert Location: Bucharest Level: > 5 years’ experience Responsibilites: Role purpose To design and architect x86 virtualization infrastructure mainly based on VMware…",
        isClicked: false
      }
    ] */

    this.sectionCont = {
      title: "Careers",
      description: "At EasyDo, you will work in a challenging and stimulating environment, where professional and personal development go hand in hand.",
      bkgImage: "assets/brooke-cagle-609873-unsplash.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor: "#14181E",
      image: "assets/dots.png",
      bkgColor1: "#2F3D56",
      bkgColor2: "#070A0E",
      bkgGradient: true,
      button: {},
      subtitle: "",
      additionalButtons: []
    };
    this.servicesColumnContent = {
      title: null,
      description: "",
      backgroundColor: "#ffffff",
      textColor: "#3e3e3e",
      boxexGridColor: "#d9d9d9",
      boxesGridWidth: "1px",
      boxes: [
        {
          img: "assets/small-diamond-svgrepo-com.svg",
          title: "A broad range of premium clients",
          description: "Our clients are of various sizes and work in different sectors ranging from financial services to public institutions, but all work with us on new and ambitious projects."
        },
        {
          img: "assets/travbuddy-social-logotype-svgrepo-com.svg",
          title: "Social involvement",
          description: "Be part of the change and promote the values of respect, ethics, openness and teamwork!"
        },
        {
          img: "assets/light-bulb-svgrepo-com.svg",
          title: "A positive environment",
          description: "You will work within a professional and dynamic team where you can be responsible for your achievements, in a work-life balanced environment."
        },
        {
          img: "assets/sport-svgrepo-com.svg",
          title: "Cultural & physically active lifestyle",
          description: "Every month we organize at least one event that brings together all our teams. These events are an opportunity to socialize and share good times with your colleagues."
        }
      ]
    }
  }

  openJob(value) {
    for (let i = 0; i < this.listJobs.job.length; i++) {
      if (value.rid == this.listJobs.job[i].rid) {
        this.listJobs.job[i].isClicked = true;
      } else {
        this.listJobs.job[i].isClicked = false;
      }
    }
  }

  hideJob(value) {
    for (let i = 0; i < this.listJobs.job.length; i++) {
      this.listJobs.job[i].isClicked = false;
    }
  }

  uploadDoc(file, elem): void {
    let fileCV: File = file.target.files[0];
    if ((fileCV.name.split('.').pop() === "pdf") || (fileCV.name.split('.').pop() === "doc") || (fileCV.name.split('.').pop() === "docx")) {
      this.isCorrectDoc = true;
    } else {
      this.isCorrectDoc = false
    }
    if (fileCV.size > 2048000) {
      this.incorrectDocSize = true;
    } else {
      this.incorrectDocSize = false;
      this.formData.set('file', fileCV, fileCV.name)
    }
    var fileInput = <HTMLInputElement>document.getElementById('file');
    var filenameContainer = <HTMLInputElement>document.getElementById('filename');
    filenameContainer.innerHTML = fileInput.value.split('\\').pop();
  }

  sendRequest() {
    if (!this.isCorrectDoc || this.incorrectDocSize) {
      return;
    }
    this.formData.set("name", this.job.name)
    this.formData.set("description", this.job.description)
    this.formData.set("email", this.job.email)
    this.formData.set("phone", this.job.phone)
    this.formData.set("position", this.job.position)
    this.formData.set("linkedin", this.job.linkedin)
    if (this.formData.get('file') == null) {
      this.formData.set('file', null)
    }
    console.log("---", this.job)
    this.service.send(`/jobs/jobApply/add`, this.formData).subscribe(
      doc => {
        this.job = new JobApplyResource();
        var filenameContainer = <HTMLInputElement>document.getElementById('filename');
        filenameContainer.innerHTML = "pdf, doc,docx - Up to 2 MB";
        var fileInput = <HTMLInputElement>document.getElementById('file');
        fileInput.value = "";
        this.route.navigate([`/confirmation`])
        window.scrollTo(500, 0);
      },
      error => console.error(error))
  }

  gotoHipo(link){
    window.open(link);
  }

}


class ListJobs {
  job: Job[]
}

class Job {
  errorCode:number;
  errorMessage:string;
  rid: number;
  name: string;
  location: string = "???????";
  description: string;
  link:string
  isClicked: boolean = false;
}
/* 
class Job {
  rid: number;
  title: string;
  location: string;
  description: string;
  isClicked: boolean = false;
} */