import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  sliderListImages:any;
  sliderListImages2:any;
  content1: any;
  content2: any;
  content3: any;
  content4: any;
  sectionCont:any;
  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "Museum of contemporary arts ",
      subtitle:"",
      description:"Our customers and success stories stand as a proof of our remarkable results.",
      image:"assets/Mnac.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{
        color:"#3993FF",
        text: "View Case Study",
        link: "/case-study-mnac"
      },
      additionalSubtitle:"Technologies Used",
      additionalButtons:[
        {
          color:"#ffffff",
          text:"Angular JS"
        },
        {
          color:"#ffffff",
          text:"Augmented reality"
        },
        {
          color:"#ffffff",
          text:"EasyDo CMS"
        }
      ]
    };

    this.sliderListImages = [
      { rid: 1,
        titleRO: "IMG1",
        titleFR: "IMG1",
        titleEN: "IMG1",
        link: "goTO....",
        image: "background3-min.jpg",
        imageString: "background3-min.jpg",
        enabled: true,
        addedDate: "02/03/2018", 
        sliderTextHeader: "",
        sliderTitle : "Enterprise solutions, Managed Services, Innovation, Data Governance, Security",
        sliderDescription : "We empower you with everything that you need to manage your organization more easily.",
        sliderBtnText: "Get in Touch"
      },
      { rid: 2,
        titleRO: "IMG1",
        titleFR: "IMG1",
        titleEN: "IMG1",
        link: "goTO....",
        image: "2softwarefactory.jpg",
        imageString: "2softwarefactory.jpg",
        enabled: true,
        addedDate: "02/03/2018", 
        sliderTextHeader: "",
        sliderTitle : "Software Factory & Innovative Business Solutions",
        sliderDescription : "We offer you a set of complementary solutions that are customizable, modular and scalable.",
        sliderBtnText: "Get in Touch"
      },
      { rid: 3,
        titleRO: "IMG1",
        titleFR: "IMG1",
        titleEN: "IMG1",
        link: "goTO....",
        image: "3infrastructureservices.jpg",
        imageString: "3infrastructureservices.jpg",
        enabled: true,
        addedDate: "02/03/2018", 
        sliderTextHeader: "",
        sliderTitle : "Infrastructure Services, Application Services & Cybersecurity",
        sliderDescription : "Our services ensure the backbone of your business, in a one-stop-shop approach.",
        sliderBtnText: "Get in Touch"
      },
      { rid: 4,
        titleRO: "IMG1",
        titleFR: "IMG1",
        titleEN: "IMG1",
        link: "goTO....",
        image: "4industrialautomation.jpg",
        imageString: "4industrialautomation.jpg",
        enabled: true,
        addedDate: "02/03/2018", 
        sliderTextHeader: "",
        sliderTitle : "Industrial Automation",
        sliderDescription : "We automate processes so that you gain the freedom to focus on your core activity.",
        sliderBtnText: "Get in Touch"
      },
      { rid: 5,
        titleRO: "IMG1",
        titleFR: "IMG1",
        titleEN: "IMG1",
        link: "goTO....",
        image: "pexels-alena-darmel-7743257.png",
        imageString: "pexels-alena-darmel-7743257.png",
        enabled: true,
        addedDate: "02/03/2018", 
        sliderTextHeader: "",
        sliderTitle : "Smart Lab, Intelligent Methods For Education",
        sliderDescription : "Easydo supports schools that want to be financed through the financing program „Equipping with intelligent laboratories of higher secondary education units, palaces and children's clubs",
        sliderBtnText: "Get in Touch"
      }
    ];
    this.content1 = {
        header: "EASYDO247",
        title: "What we do",
        description: "The success of our strategy is based on 3 pillars, enabling us to position ourselves as the optimal Services & Solutions Partner for our Clients.",
        imgs:[
          {
            src:"assets/coding.png",
            description: ["1. Software factory & Innovative Business Solutions"],
            textOver: false
          },
          {
            src:"assets/cyber.png",
            description: ["2. Infrastructure Services, Application Services & Cybersecurity"],
            textOver: false
          },
          {
            src:"assets/industry.png",
            description: ["3. Industrial Automation"],
            textOver: false
        }],
        button:{
          color:"#3993FF",
          text: "Our Company",
          link: "/our-company"
        }
    }

    this.content2 = {
        header: "",
        headerImg: "assets/hexa_2.png",
        headerBKGColor1: "#1c2739",
        headerBKGColor2: "#040C19",
        textColor:"#ffffff",
        title: "What we focus on",
        description: "From the get-go we set up 3 goals in order to become leaders in the IT field. Focusing on Change, Control & Industrialization our expertise goes beyond regular IT companies.",
        imgOverlayColor:"#14181E",
        imgs:[
          {
            src:"assets/laptop-3190194_640.png",
            description: ["1. "," Change"],
            descriptionHover:"Inovation, Digitalization & Transformation, Governance, performance",
            textOver: true
          },
          {
            src:"assets/security-265130_640.png",
            description: ["2. ","Control"],
            descriptionHover:"Inovation, Digitalization & Transformation, Governance, performance",
            textOver: true
          },
          {
            src:"assets/robot-2791671_640.png",
            description: ["3. ","Industrialization"],
            descriptionHover:"Inovation, Digitalization & Transformation, Governance, performance",
            textOver: true
        }],
        button:{}
    }
    this.content3 = {
        header: "EASYDO247",
        headerBKGColor1: "#FAFAFC",
        headerImg: "",
        title: "Customer feedback",
        description: "Here`s how clients feel about our collaborations and help with their businesses.",
        imgOverlayColor:"",
        imgs:[],
        button:{}
    }
    

    }

}
