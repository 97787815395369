import { Component, OnInit } from '@angular/core';
import { slideAnimation } from '../slide.animation';
import { Input } from "@angular/core";

@Component({
  selector: 'vbn-slider-transition',
  templateUrl: './slider-transition.component.html',
  styleUrls: ['./slider-transition.component.scss'],
  animations: [slideAnimation]
})
export class SliderTransitionComponent {

  slides: any;
  slideSelectedCrt: any;
  index: number = 0;
  displayDotImage:any;
  
  @Input()
  sliderList: string;

  @Input()
  displayDots: boolean;

  currentIndex = 0;
  // slides = [
  //   {image: 'assets/img00.jpg', description: 'assets/lianhao-qu-1583918-unsplash.png'},
  //   {image: 'assets/img01.jpg', description: 'assets/robot-shaking-hands.png"'},
  //   {image: 'assets/img02.jpg', description: 'assets/company.png'}
  // ];

  constructor() {
    // this.preloadImages();
  }

  ngOnInit(){
    this.slides = this.sliderList;
    this.slideSelectedCrt = this.slides[0];
    this.index = 0;
    // this.slideShow();
    this.displayDotImage = this.displayDots;
    this.preloadImages();
    this.autoChange();
  }

  autoChange(){
    setInterval(() => {
      this.prevSlide();
    }, 7000);
  }

  preloadImages() {
    this.slides.forEach(slide => {
      (new Image()).src = slide.image;
    });
  }

  setCurrentSlideIndex(index) {
    this.currentIndex = index;
  }

  isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex < this.slides.length - 1) ? ++this.currentIndex : 0;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex > 0) ? --this.currentIndex : this.slides.length - 1;
  }

}
