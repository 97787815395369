
export class ContactUsResource {
	rid: number;
	message: string;
	userName: string;
	userEmail: string;
	userPhone: string;
	keepMyPersonalData: boolean;
	organisation: string;
}
