import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vbn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  content4:any;
  
  constructor(private _route: Router) { }

  ngOnInit() {
    this.content4 = {
      header: "",
      headerImg: "",
      headerBKGColor1: "#FF4143",
      headerBKGColor2: "#CE0000",
      textColor:"#ffffff",
      title: "Ready to start your project ? ",
      description: "With satisfied clients all over the world and market leaders as partners, your project’s success will skyrocket!",
      /* imgOverlayColor:"#292323ab", */
      imgs:[],
      button:{
        color:"#F9383A",
        text: "GET IN TOUCH ",
        bkgColor:"#ffffff",
        bold:true,
        link:"mailto:sales@easydo.co"
      }
  }
  }
  goToContact(ev) {
    var currentEvent = document.getElementById("contact");
    window.scrollTo(0, currentEvent.offsetTop);
  }

  goToGdprTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  goToCookieTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  goToHome() {
    this._route.navigate(['/home'])
		window.scrollTo(500, 0);
  }
  

	goToSoc() {
		this._route.navigate(['/soc'])
	}

	goToCustomer() {
		this._route.navigate(['/customer-case-study'])
	}


}
