import { Component, HostListener, OnInit, Output } from '@angular/core';
import { AppService } from "app/app.service";
import { ContactUsResource } from "app/models/ContactUsResource";
import { ValidatorFn } from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { EventEmitter } from 'protractor';
import { MessageService } from 'app/message-service.service';

@Component({
  selector: 'vbn-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent implements OnInit {

  contactUs: ContactUsResource;
  errorEmail: boolean = false;
  errorGdpr: boolean = false;
  errorMessage: boolean = false
  constructor(private service: AppService, private messageService: MessageService, private fb: FormBuilder, private route: Router) {
    this.contactUs = new ContactUsResource();
  }

  close_popup() {
    this.messageService.sendMessage({
      type: "closePopup",
      closePopup: true
    });
  }

  ngOnInit() {
  }

  addForm = this.fb.group({
    email: ['', [Validators.required]],
    name: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    challenge: ['', [Validators.required]],
  })

  sendContact() {
    if (!this.addForm.valid) {
      this.errorMessage = true;
      return
    }
    this.errorEmail = false;
    this.errorGdpr = false;
    if (!this.contactUs.keepMyPersonalData) {
      this.errorGdpr = true;
      return;
    }
    let correctEmail;
    let checkEmail;
    if (this.contactUs != undefined) {
      if (this.contactUs.userEmail != null) {
        checkEmail = this.contactUs.userEmail.indexOf('@');
        if (this.contactUs.userEmail.includes('.', checkEmail)) {
          correctEmail = true;
        }
        else {
          correctEmail = false;
          this.errorEmail = true;
          return;
        }
      }
    }
    this.service.send(`/messageDepartment/contactUs`, this.contactUs)
      .subscribe(
        body => {
          this.contactUs = new ContactUsResource();
          this.errorEmail = false;
          this.errorGdpr = false;
          this.close_popup();
          this.route.navigate([`/confirmation`])
          window.scrollTo(500, 0);
        },
        error => {
          console.error(error)
        })

  }
  emailValidateWithDot(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let correctEmail;
      let checkEmail;
      if (this.contactUs != undefined) {
        if (this.contactUs.userEmail != null) {
          checkEmail = this.contactUs.userEmail.indexOf('@');
          if (this.contactUs.userEmail.includes('.', checkEmail)) {
            correctEmail = true;
          }
          else {
            correctEmail = false;
          }
          return correctEmail ? null : { 'correctEmail': 'email incorect' }
        }
      }
    };
  }

  isNumberWithDot(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // addForm = this.fb.group({
  //   writeUsEmail: ['', [Validators.required, Validators.email, this.emailValidateWithDot()]]
  // })

}
