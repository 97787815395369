import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-products-cms',
  templateUrl: './products-cms.component.html',
  styleUrls: ['./products-cms.component.scss']
})
export class ProductsCmsComponent implements OnInit {

  sectionContent: any;
  sectionCont:any;
  productsColumnContent:any;
  sectionList:any;

  constructor() { }

  ngOnInit() {

    this.sectionCont = {
      title: "EasyDo CMS",
      description:"Museums, art galleries and other institutions must find new ways to tell stories and engage their audiences. Being underfunded and understaffed is enough of a challenge, you shouldn’t also have your job made more difficult by being behind the curve in digital adoption and automation.",
      bkgImage:"assets/headway-537308-unsplash.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };

    this.sectionContent = {
      isDots: false,
      dotsImg: null,
      isImg: false,
      img: "",
      isVideo: false,
      link: null,
      title: "",
      titleImage:"assets/Artboards-LogoEasyDoCms.png",
      description:"The EasyDo Content Management System (CMS) will help you on all these fronts: it’s a user-friendly web platform that brings together a core Content Management System and all the integrated services that your organization might need, such as e-Commerce, Newsletters, Social Media, Interactive content and QR codes, CRM, online ticket selling, reservations and customer support. Our Digital platform orchestrates all flows: internal, external with partners and clients.",
    }

    this.productsColumnContent = {
      title: "Features",
      description: "",
      backgroundColor:"#fff",
      textColor:"#000",
      boxexGridColor:"#fff",
      boxesGridWidth:"0px",
      fullWidth: true,
      boxes: [

        {
          img: "assets/emoticon-square-face-with-a-smile-svgrepo-com.svg",
          title: "Friendly user experience",
          description: ""
        },
        {
          img: "assets/settings-setup-svgrepo-com.svg",
          title: "Custom menu setup",
          description: ""
        },
       {
          img: "assets/content-management-svgrepo-com (1).svg",
          title: "Website content management",
          description: ""
        },
        {
          img: "assets/commercial-event-on-calendar-svgrepo-com.svg",
          title: "Event management",
          description: ""
        },
        {
          img: "assets/envelope-svgrepo-com.svg",
          title: "Newsletters",
          description: ""
        },
        {
          img: "assets/social-media-campaign-svgrepo-com.svg",
          title: "Social Media integration",
          description: ""
        },
        {
          img: "assets/credit-card-shopping-svgrepo-com.svg",
          title: "Online payments & e-Commerce",
          description: ""
        },
        {
          img: "assets/responsive-devices-svgrepo-com.svg",
          title: "Responsive design",
          description: ""
        }
      ]
    }

    this.sectionList = {
      title: "Your benefits from using the EasyDo Content Management System:",
      checkMark: true,
      contentList: [
        "Visibility for your company in the online environment: our team of experts can deliver the right solution to meet your business requirements while helping you reach your target market",
        "Differentiation from competition",
        "The right combination of digital solutions to increase your sales volume",
        "Security & reliability",
        "Multilingual content options to help you market to global audiences",
        "Integration with other solutions built by us or our partners",
        "API support Marketing campaigns management",
        "Cost-effectiveness and economies of scale"
      ]
    }



  }

}
