/* tslint:disable */
import { NodeItemResource } from "./NodeItemResource"

export class MenuButtonResource {
	color: string;
	descriptionRO: string;
	descriptionEN: string;
	descriptionFR: string;
	errorCode: number;
	errorMessage: string;
	externalButton: boolean;
	image: string;
	imageString: string;
	link: string;
	menuIndex: number;
	nameRO: string;
	nameEN: string;
	nameFR: string;
	noOfChilds: number;
	parrentButtonId: number;
	rid: number;
	universeId: number;
	widget: boolean;
	childButtons: MenuButtonResource[];
	nodes: MenuButtonResource[];
	nodeItemResource: NodeItemResource;

	isClicked: boolean = false;
	showSecondlane: boolean = false;
	treeLevel: number = 0;
	moveToLeft: boolean = false;
}
