// import { OpaqueToken } from "@angular/core";
import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken("app.config");

export interface CmsAppConfig {
    recaptcha: string;
}

export const AppConfig: CmsAppConfig = {    
    recaptcha: "6Lev70oUAAAAAD8OBsHRfW952uA3K6b3XiF4K6e1"    
};