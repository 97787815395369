import { Component, OnInit } from '@angular/core';
import { Input } from "@angular/core";

@Component({
  selector: 'vbn-section-list-description',
  templateUrl: './section-list-description.component.html',
  styleUrls: ['./section-list-description.component.scss']
})
export class SectionListDescriptionComponent implements OnInit {

  @Input()
  sectionContent

  constructor() { }

  ngOnInit() {
  }

}
