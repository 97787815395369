import { Component, OnInit } from '@angular/core';
import { servicesVersion } from 'typescript';

@Component({
  selector: 'vbn-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  sectionCont: any;
  servicesColumnContent: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "We're a one-stop-shop provider",
      description: "When you work with us, you receive management of your entire tech package from end to end. This involves technical expertise, consistent service, software-as-a-service and custom-built solutions, infrastructure and security services, as well as integration and management of a wide range of third-party products.",
      bkgImage: "assets/services.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor: "#14181E",
      image: "assets/dots.png",
      bkgColor1: "#2F3D56",
      bkgColor2: "#070A0E",
      bkgGradient: true,
      button: {},
      subtitle: "",
      additionalButtons: []
    };

    this.servicesColumnContent = {
      title: "Services",
      description: "Businesses typically use a mix of proprietary software and SaaS, as well as custom and legacy systems. When you work with us, we manage all of your technology aspects from end to end. This involves technical expertise, consistent service, software-as-a-service and custom-built solutions, infrastructure and security services, as well as integration and management of a wide range of third-party products.",
      backgroundColor:"",
      textColor:"#1E2534",
      boxexGridColor:"#C9C9C9",
      boxesGridWidth:"1px",
      boxes: [

        {
          img: "assets/managedservices.png",
          title: "Managed  Services",
          description: "",
          linkTo:"/managed-services"
        },
        {
          img: "assets/cloud.png",
          title: "SaaS Solutions  & Services",
          description: "",
          linkTo:"/services-sas"
        },
       {
          img: "assets/desktop_analytics.png",
          title: "Security Operations Center ( SOC )",
          description: "",
          linkTo:"/soc-service"
        },
        {
          img: "assets/integration.png",
          title: "Integration Services",
          description: "",
          linkTo:"/integration-service"
        }
      ]

    }
  }




}
