import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'vbn-services-sas',
  templateUrl: './services-sas.component.html',
  styleUrls: ['./services-sas.component.scss']
})
export class ServicesSasComponent implements OnInit {
  columnContent:any;
  sectionCont: any;
  // sectionCont2: any;
  @Input()
  sectionContent
  content3: { title: string; description: string; }[];
  imageAndContent: any;
  imageAndContent2: any;
  imageAndContent3: any;


  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "SaaS Solutions",
      description: "Our customizable software-as-a-service platform is designed with a client-centric focus, with tools that were developed with several organization types in mind: Financial institutions, Manufacturing companies, Public Administration, Museums, Art Galleries and other Cultural institutions.",
      bkgImage: "assets/Saassolutions.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor: "#14181E",
      image: "assets/dots.png",
      bkgColor1: "#2F3D56",
      bkgColor2: "#070A0E",
      bkgGradient: true,
      button: {},
      subtitle: "",
      additionalButtons: []
    };

    this.columnContent ={
      title:"",
      cols: [
          {
            title:"Shorter time to benefit",
            description: "Shorter time to benefit – you can use the tools that you need in a matter of hours, without the time spent in installation and configuration.",
            button:{
            },
          },
          {
            title:"Cost savings",
            description:"- in terms of hardware and software licenses, as well as maintenance costs. Additionally, our feature-based packages ensure that you only pay for what you need, as your organization grows.",
            button:{
            },
          },
          {
            title:"Scalability and integration",
            description:"As your organization’s needs evolve, you do not have to buy another server or software, you can simply enable a new SaaS offering.",
            button:{
            },
          }
        ]
      }


      this.imageAndContent = {
        imageLeft: false,
        imageRight: true,
        img:"assets/sas-cas.png",  //the image from -left side
        content:{   //text content from right side
          title:"", 
          subtitle:"",
          topContent:"<strong> Financial institutions </strong> need robust support in terms of security and processing large volumes of data.",
          checkMark: false,
          contentList:[
            "",
          ],
          additionalContent:'', //text at the bottom of this content
        }
      }

      this.imageAndContent2 = {
        imageLeft: false,
        imageRight: true,
        img:"assets/alina.png",  //the image from -left side
        content:{   //text content from right side
          title:"", 
          subtitle:"",
          topContent:"Museums, art galleries and other institutions must find new ways to tell stories and engage their audiences. Being underfunded and understaffed is enough of a challenge, you shouldn’t also have your job made more difficult by being behind the curve in digital adoption and automation.",
          checkMark: false,
          contentList:[
            "",
          ],
          additionalContent:'', //text at the bottom of this content
        }
      }

      this.imageAndContent3 = {
        imageLeft: true,
        imageRight: false,
        img:"assets/revolt.png",  //the image from -left side
        content:{   //text content from right side
          title:"", 
          subtitle:"",
          topContent:"<strong> Manufacturing companies </strong> and <strong> administrative organizations </strong> often find themselves overwhelmed and bogged down by the challenges of managing large volumes of resources, processes, data bases and relationships with various types of stakeholders.",
          checkMark: false,
          contentList:[
            "",
          ],
          additionalContent:'', //text at the bottom of this content
        }
      }
  }

}
