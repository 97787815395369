import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { MessageService } from "app/message-service.service";

@Component({
  selector: 'vbn-soc',
  templateUrl: './soc.component.html',
  styleUrls: ['./soc.component.scss']
})
export class SocComponent implements OnInit {

  language = localStorage.getItem("vbn-language");

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  subscription: Subscription;

  constructor(private messageService: MessageService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.language = message.changedLanguage;
    });
  }

  ngOnInit(){

  }

}
