import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-integration-service',
  templateUrl: './integration-service.component.html',
  styleUrls: ['./integration-service.component.scss']
})
export class IntegrationServiceComponent implements OnInit {

  sectionCont: any;
  imageAndContent: any;
  imageAndContent2: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "Integration Services",
      description:"We meet the changing needs of organizations as they deal with increasingly complex IT challenges while operating on tighter budgets. To help you support your clients, we integrate custom applications and third-party solutions developed by our partners.",
      bkgImage:"assets/puzzle-3223941_1920.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };
    this.imageAndContent = {
      imageLeft: false,
      imageRight: true,
      img:"assets/cable-541064_640.png",  //the image from -left side
      content:{   //text content from right side
        title:"Why choose us as Integrators ?", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "We have long-standing partnerships with market leaders.",
          "We provide consulting services to identify the best approach for digitalizing your business.",
          "Our integration services are complemented by automation or implementation of standard security or applications.",
          "Our consultants can work at your offices or from our service center, whichever works best for you.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.imageAndContent2 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/maxresdefault.png",  //the image from -left side
      content:{   //text content from right side
        title:"Integration of Axway AMPLIFY API Management", 
        subtitle:"",
        topContent:"",
        checkMark: true,
        contentList:[
          "Capacity to deliver consulting services",
          "Highly experienced local team to accompany your project through all phases, from definition to implementation, change management and roll out ",
          "Architecture adapted to your needs, in order to optimize the digital transformation ",
          "An experienced team with a solid background in financial services ",
          "Our business and technical capabilities are enhanced by Axway’s experience, the leader in data governance ",
          "Project implementation becomes easier, faster, and you gain more control over it ",
          "Capacity to deliver resources: PM, Analyst, Architect, Developer, Engineer, Tester, Trainer",
          "Local support & maintenance in different modes, including 24 x 7, for the roll out"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
  }
  
    
}
