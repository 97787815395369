import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'trim'
})
export class TrimPipe implements PipeTransform {

	transform(value: string, args: any): string {
		if (typeof value === 'string') {
			return value.substring(0, parseInt(args))
		}
		return ""
	}
}
