export class NewsletterResource {
    email: string;
    errorCode: number;
    errorMessage: string;
    news: boolean;
    event: boolean;
    rid: number;
    keepMyPersonalData: boolean;
    agreeToReceiveNewsletters: boolean;
}
