import { Component, OnInit } from '@angular/core';
import { Globals } from "app/globals";
import { Router } from "@angular/router";

@Component({
  selector: 'vbn-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent implements OnInit {

  constructor(private globals: Globals, private route: Router) {
    this.globals.showConfirmationPage = true;
   }

  ngOnInit() {
    
  }

  goBack() {
    this.globals.showConfirmationPage = false;
    this.route.navigate([`/home`]);
  }

}
