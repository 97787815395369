import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslatePoHttpLoader } from '@biesbjerg/ngx-translate-po-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicPageComponent } from './public-page/public-page.component';
import { AppService } from "app/app.service";
import { MainMenuComponent } from './main-menu/main-menu.component';
// translate pipes
import { SafeHtmlPipe } from './safe-html.pipe';
import { TrimPipe } from './trim.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { TranslateJsPipe } from './translate-js.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OnclickoutDirectiveDirective } from './onclickout-directive.directive';
import { TrustUrlPipe } from './trust-url.pipe';
import { ListSlicePipe } from './list-slice.pipe';
import { SliderHomepageComponent } from './slider-homepage/slider-homepage.component';
import { NewTabDirective } from './new-tab.directive';
import { MessageService } from "app/message-service.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_CONFIG, AppConfig } from './app.config';
import { MenuRoutes } from './main-menu/menu-routes';
// Service
import { MainMenuService } from './main-menu/main-menu.service';
import { SliderHomepageService } from './slider-homepage/slider-homepage.service';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { Ng2DeviceService } from "ng2-device-detector";
import { HeaderComponent } from './header/header.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FooterComponent } from './footer/footer.component';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { Globals } from "app/globals";
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { GdprCommunicationComponent } from './gdpr-communication/gdpr-communication.component';
import { GdprAgreementComponent } from './gdpr-agreement/gdpr-agreement.component';
import { ContentTextComponent } from './content-text/content-text.component';
import { ContentTextService } from './public-page/content-text.service';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { SocComponent } from './soc/soc.component';
import { CustomerCaseStudyComponent } from './customer-case-study/customer-case-study.component';
import { AboutComponent } from './about/about.component';
import { AboutCompComponent } from './about-comp/about-comp.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SectionDescriptionComponent } from './section-description/section-description.component';
import { SectionDescriptionBkgComponent } from './section-description-bkg/section-description-bkg.component';
import { SectionPartnersComponent } from './section-partners/section-partners.component';
import { SlideHompage2Component } from './slide-hompage2/slide-hompage2.component';
import { OurCompanyComponent } from './our-company/our-company.component';
import { SectionColContentComponent } from './section-col-content/section-col-content.component';
import { SectionImageContentComponent } from './section-image-content/section-image-content.component';
import { ServicesComponent } from './services/services.component';
import { ServicesContentComponent } from './services-content/services-content.component';
import { ServicesSasComponent } from './services-sas/services-sas.component';
import { ManagedServicesComponent } from './managed-services/managed-services.component';
import { IntegrationServiceComponent } from './integration-service/integration-service.component';
import { SliderTransitionComponent } from './slider-transition/slider-transition.component';
import { SocServicesComponent } from './soc-services/soc-services.component';
import { SectionMiddleImageComponent } from './section-middle-image/section-middle-image.component';
import { Products2Component } from './products2/products2.component';
import { ProductsAxwayComponent } from './products-axway/products-axway.component';
import { SlideHompage3Component } from './slide-hompage3/slide-hompage3.component';
import { ProductsSasComponent } from './products-sas/products-sas.component';
import { SectionListDescriptionComponent } from './section-list-description/section-list-description.component';
import { ProductsComponent } from './products/products.component';
import { ProductsCmsComponent } from './products-cms/products-cms.component';
import { ProductsEventsComponent } from './products-events/products-events.component';
import { EasydoAccessComponent } from './easydo-access/easydo-access.component';
import { ContactComponent } from './contact/contact.component';
import { CaseStudyMnacComponent } from './case-study-mnac/case-study-mnac.component';
import { CaseStudyGolestiComponent } from './case-study-golesti/case-study-golesti.component';
import { CareersComponent } from './careers/careers.component';
import { FooterNewsletterComponent } from './footer-newsletter/footer-newsletter.component';
import { GdprCreateSubscriberComponent } from './gdpr-create-subscriber/gdpr-create-subscriber.component';
import { SmartLabComponent } from './smart-lab/smart-lab.component';
import { HologramComponent } from './smart-lab/hologram/hologram.component';
import { ContactPopupComponent } from './contact-popup/contact-popup.component';


registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
	return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

@NgModule({
	declarations: [
		AppComponent,
		PublicPageComponent,
		MainMenuComponent,
		SafeHtmlPipe,
		TrimPipe,
		CapitalizePipe,
		TranslateJsPipe,
		OnclickoutDirectiveDirective,
		TrustUrlPipe,
		ListSlicePipe,
		SliderHomepageComponent,
		NewTabDirective,
		HeaderComponent,
		ContactUsComponent,
		ContactPopupComponent,
		FooterComponent,
		ConfirmationPageComponent,
		CookiePolicyComponent,
		GdprCommunicationComponent,
		GdprAgreementComponent,
		ContentTextComponent,
		VerticalMenuComponent,
		SocComponent,
		CustomerCaseStudyComponent,
		AboutComponent,
		AboutCompComponent,
		HomePageComponent,
		SectionDescriptionComponent,
		SectionDescriptionBkgComponent,
		SectionPartnersComponent,
		SlideHompage2Component,
		SlideHompage3Component,
		OurCompanyComponent,
		SectionColContentComponent,
		SectionImageContentComponent,
		ServicesComponent,
		ServicesContentComponent,
		ServicesSasComponent,
		ManagedServicesComponent,
		IntegrationServiceComponent,
		SliderTransitionComponent,
		SocServicesComponent,
		SectionMiddleImageComponent,
		Products2Component,
		ProductsAxwayComponent,
		ProductsSasComponent,
		SectionListDescriptionComponent,
		ProductsComponent,
		ProductsCmsComponent,
		ProductsEventsComponent,
		EasydoAccessComponent,
		ContactComponent,
		CaseStudyMnacComponent,
		CaseStudyGolestiComponent,
		CareersComponent,
		FooterNewsletterComponent,
		GdprCreateSubscriberComponent,
		SmartLabComponent,
		HologramComponent
	],
	imports: [
		FormsModule,
		BrowserModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		AppRoutingModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		AppService,
		MenuRoutes,
		MessageService,
		{ provide: APP_CONFIG, useValue: AppConfig },
		MainMenuService,
		SliderHomepageService,
		Ng2DeviceService,
		Globals,
		ContentTextService
	],
	
		
	bootstrap: [AppComponent]
})
export class AppModule { }
