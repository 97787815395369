import { NodeItemResource } from "app/models/NodeItemResource";

export class ContentTextResource {
    errorCode: number;
    errorMessage: string;
    rid: number;
    textRO: string;
    textEN: string;
    textFR: string;
    titleRO: string;
    titleEN: string;
    titleFR: string;
    userId: number;
    nodeItemResource: NodeItemResource;
}