import { Component, OnInit } from '@angular/core';
import { MenuButtonResource } from "app/models/MenuButtonResource";
import { MenuButtonListResource } from "app/models/MenuButtonListResource";
import { EventEmitter } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { Router } from "@angular/router";
import { MainMenuService } from "app/main-menu/main-menu.service";
import { MenuRoutes } from "app/main-menu/menu-routes";
import { Globals } from "app/globals";

@Component({
  selector: 'vbn-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class VerticalMenuComponent implements OnInit {

  rootMenu: MenuButtonListResource[];
	menuList: MenuButtonListResource[];
	currentList: any;
	elementWidth: any;
	treeLevel1: boolean = false;
	treeLevel2: boolean = false;
	treeLevel3: boolean = false;

	@Input()
	set treeData(treeData: MenuButtonListResource) {
		this.menuList = Object.assign([], treeData)
		console.log("am trecut pe aici", treeData)
	}

	@Output() back: EventEmitter<any> = new EventEmitter();
	@Output() toMainMenu: EventEmitter<any> = new EventEmitter();


	constructor(private mainMenuService: MainMenuService, private _router: Router, private menuRoutes: MenuRoutes, private globals: Globals,) {
	}

	ngOnInit() {
		this.rootMenu = Object.assign([], this.menuList);
	}

	getMenu() {
		this.mainMenuService.getMenu(`/menu/publicMain/get`).subscribe(
			(menu_data: MenuButtonListResource) => {
				this.menuList = Object.assign([], menu_data.buttons);
			},
			error => console.error(<any>error))
	}

	getSecondLineMenu(item: MenuButtonResource) {
		if (item.noOfChilds > 0) {
			if (item.treeLevel == 0) {
				if ((this.menuList.length >= 2 && item.menuIndex == this.menuList.length - 1) 
					|| (this.menuList.length >= 4 && item.menuIndex == this.menuList.length - 2)  
					|| (this.menuList.length >= 6 && item.menuIndex == this.menuList.length - 3)) {
					this.globals.verticalMenuToLeft = true;
					this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${item.rid}`).subscribe(
						(menu_data: MenuButtonListResource) => {
							for (let i = 0; i < this.menuList.length; i++) {
								this.menuList[i].isClicked = false;
							}
							for (let i = 0; i < menu_data.buttons.length; i++) {
								if (menu_data.buttons[i].parrentButtonId == item.rid) {
									menu_data.buttons[i].treeLevel = item.treeLevel + 1;
									menu_data.buttons[i].color = item.color;
									menu_data.buttons[i].moveToLeft = true;
								}
								if (menu_data.buttons[i].treeLevel == 1) {
									this.treeLevel1 = true;
									this.treeLevel2 = false;
									this.treeLevel3 = false;
									this.getBackgroundColor(item.color, item.treeLevel)
								} else if (menu_data.buttons[i].treeLevel == 2) {
									this.treeLevel1 = false;
									this.treeLevel2 = true;
									this.treeLevel3 = false;
								} else if (menu_data.buttons[i].treeLevel == 3){
									this.treeLevel1 = false;
									this.treeLevel2 = false;
									this.treeLevel3 = true;
								}
							}
							item.nodes = menu_data.buttons
							item.isClicked = true;
						},
						error => console.error(<any>error))
				} else {
					this.globals.verticalMenuToLeft = false;
					this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${item.rid}`).subscribe(
						(menu_data: MenuButtonListResource) => {
							for (let i = 0; i < this.menuList.length; i++) {
								this.menuList[i].isClicked = false;
							}
							for (let i = 0; i < menu_data.buttons.length; i++) {
								if (menu_data.buttons[i].parrentButtonId == item.rid) {
									menu_data.buttons[i].treeLevel = item.treeLevel + 1;
									menu_data.buttons[i].color = item.color;
								}
								if (menu_data.buttons[i].treeLevel == 1) {
									this.treeLevel1 = true;
									this.treeLevel2 = false;
									this.treeLevel3 = false;
									this.getBackgroundColor(item.color, item.treeLevel)
								} else if (menu_data.buttons[i].treeLevel == 2) {
									this.treeLevel1 = false;
									this.treeLevel2 = true;
									this.treeLevel3 = false;
								} else if (menu_data.buttons[i].treeLevel == 3){
									this.treeLevel1 = false;
									this.treeLevel2 = false;
									this.treeLevel3 = true;
								}
							}
							item.nodes = menu_data.buttons
							item.isClicked = true;
						},
						error => console.error(<any>error))
				}
			} else {
					this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${item.rid}`).subscribe(
						(menu_data: MenuButtonListResource) => {
							for (let i = 0; i < this.menuList.length; i++) {
								this.menuList[i].isClicked = false;
							}
							for (let i = 0; i < menu_data.buttons.length; i++) {
								if (menu_data.buttons[i].parrentButtonId == item.rid) {
									menu_data.buttons[i].treeLevel = item.treeLevel + 1;
									menu_data.buttons[i].color = item.color;
									if(this.globals.verticalMenuToLeft){
										menu_data.buttons[i].moveToLeft = true;
									}
								}
								if (menu_data.buttons[i].treeLevel == 1) {
									this.treeLevel1 = true;
									this.treeLevel2 = false;
									this.treeLevel3 = false;
									this.getBackgroundColor(item.color, item.treeLevel)
								} else if (menu_data.buttons[i].treeLevel == 2) {
									this.treeLevel1 = false;
									this.treeLevel2 = true;
									this.treeLevel3 = false;
								} else if (menu_data.buttons[i].treeLevel == 3){
									this.treeLevel1 = false;
									this.treeLevel2 = false;
									this.treeLevel3 = true;
								}
							}
							item.nodes = menu_data.buttons
							item.isClicked = true;
						},
						error => console.error(<any>error))
				}
		} else {
			for (let i = 0; i < this.menuList.length; i++) {
				this.menuList[i].isClicked = false;
			}
			// this.goTo(item);
		}
	}

	goTo(item: MenuButtonResource) {
		if (item.nodeItemResource != null) {
			if (item.nodeItemResource.contentTemplate != null) {
				window.scroll(500, 0);
				this._router.navigate(MenuRoutes.fetch(item))
				this.back.emit(item); //de aici se inchide meniu insa am bug
			}
		}
		if (item.externalButton === true) {
			window.open(item.link, '_blank')
		}
	}

	getBackgroundColor(color, level) {
		if (level > 0) {
			if (color) {
				return '#' + color;
			}
		} else {
			return 'transparent';
		}
	}

	check(ev: MenuButtonResource) {
		let parentList: MenuButtonListResource
		console.log("menulist----", this.menuList)
		this.mainMenuService.getButton(`/menu/getButton/${ev.parrentButtonId}`).subscribe(
			response => {
				this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${response.rid}`).subscribe(
					(menu_data: MenuButtonListResource) => {
						this.menuList = Object.assign([], menu_data.buttons);
						this.menuList = [];
						// this.getMenu();
						this.back.emit(ev);
						this.toMainMenu.emit(true);
					},
					error => console.error(<any>error))

			},
			error => console.error(<any>error))
	}

}
