import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-products-sas',
  templateUrl: './products-sas.component.html',
  styleUrls: ['./products-sas.component.scss']
})
export class ProductsSasComponent implements OnInit {

  sectionCont: any;
  sectionContent: any;
  sectionList: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "SAS",
      description:"EasyDo is an official partner and integrator of SAS products, to complement our proprietary offers for Enterprise clients.",
      bkgImage:"assets/analytics-2618277_1280.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };

    this.sectionContent = {
      isDots: false,
      dotsImg: null,
      isImg: true,
      img: "assets/sas.png",
      isVideo: false,
      link: null,
      title: "What is SAS ?",
      description:"SAS is an integrated software suite for <strong>advanced analytics, business intelligence, data management,</strong> and <strong>predictive analytics</strong>. You can use SAS software through both a graphical interface and the SAS programming language, or Base SAS. Numerous SAS modules for web, social media and marketing analytics may be used to profile customers and prospects, predict their behaviors and manage and optimize communications.",
    }

    this.sectionList = {
      title: "With SAS software, you can:",
      checkMark: true,
      contentList: [
        "Access data in almost any format, including SAS tables, Microsoft Excel tables, and database files.",
        "Manage and manipulate your existing data to get the insight that you need. For example, you can subset your data, combine it with other data, and create new columns.",
        "Analyze your data using statistical techniques ranging from descriptive measures like correlations to logistic regression and mixed models to sophisticated methods such as modern model selection and Bayesian hierarchical models.",
        "Present the results of your analyses in a meaningful report that you can share with others. The reports that you create can be saved in a wide variety of formats, including HTML, PDF, and RTF."
      ]
    }
  }

}
