import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'vbn-section-description-bkg',
  templateUrl: './section-description-bkg.component.html',
  styleUrls: ['./section-description-bkg.component.scss']
})

  //this text component it is used on the HomePage, the "Museum of contemporary arts" section
  //on the left side there is a text(center aligned), a button and 
  //an alternativ text with 3 buttons
  //On the right side we can insert an image.
  //The background is gradient or can be used an image as bkg

  //On the Our Company page, this component is used only with the main text, left align and an image as the background

export class SectionDescriptionBkgComponent implements OnInit {

  content: any;
  backgroundImage:boolean;

  @Input()
  sectionContent

  @Input()
  bkgImage

  @Input()
  textAlignLeft //if true then the text from .section-text will be left align. e.g top Section from Our Company

  @Input()
  ifDotsThenCenter

  contentTextSafeHtml: SafeHtml;
  constructor( private sanitizer:DomSanitizer, private translate: TranslateService) { }

  ngOnInit() {
    this.content = this.sectionContent;
    this.backgroundImage = this.bkgImage;
    this.translate.stream(this.content.description).subscribe(
      transl => {
        this.contentTextSafeHtml = this.sanitizer.bypassSecurityTrustHtml(transl);
      }
    );
  }

}
