import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vbn-section-col-content',
  templateUrl: './section-col-content.component.html',
  styleUrls: ['./section-col-content.component.scss']
})
export class SectionColContentComponent implements OnInit {

  @Input()
  sectionContent
  
  constructor(private _route: Router) { }

  ngOnInit() {
  }

  goToPage(link){
    let linkArr = link.split("#");
    this._route.navigate([linkArr[0]]).then(()=>{
      if(linkArr[1]){
        var currentEvent = document.getElementById(linkArr[1]);
         window.scrollTo(0, currentEvent.offsetTop);
      }else{
        window.scrollTo(500, 0);
      }
    });
    
    
  }

}
