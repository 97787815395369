import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sliceArr'
})
export class ListSlicePipe implements PipeTransform {
	transform(value: Array<any>, arg1?: any, arg2?: any): any {
		return value.slice(arg1, arg2);
	}

}
