import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'vbn-section-image-content',
  templateUrl: './section-image-content.component.html',
  styleUrls: ['./section-image-content.component.scss']
})
export class SectionImageContentComponent implements OnInit {

  topContent: SafeHtml;

  @Input()
  sectionContent
  
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.topContent = this.sanitizer.bypassSecurityTrustHtml(this.sectionContent.content.topContent)
  }

}
