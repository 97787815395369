import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { CmsAppConfig, APP_CONFIG } from './app.config';
import { HttpClient } from "@angular/common/http";
import { environment } from "app/baseurl";

@Injectable()
export class AppService {


	constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: CmsAppConfig) {
	}

	send(url, body): Observable<any>{
		return this.http.post<any>(environment.url + url, body)
	}

	pdf(url): Observable<any>{
		return this.http.get<any>(environment.url + url)
	}

	getContent(url): Observable<any> {
		return this.http.get<any>(environment.url + url);
	}

	getDocument(url): Observable<any> {
		return this.http.get<any>(environment.url + url);
	}

	upload(url, body): Observable<any> {
		return this.http.post<any>(environment.url + url, body)
	}
}
