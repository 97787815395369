import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ContentTextService } from './content-text.service';
import { HomepageContentList } from 'app/models/HomepageContentList';
import { HomepageContentResource } from 'app/models/HomepageContentResource';

@Component({
  selector: 'vbn-public-page',
  templateUrl: './public-page.component.html',
  styleUrls: ['./public-page.component.scss']
})
export class PublicPageComponent implements OnInit {

  errorMessage: any;
  id: number;
  htmlBodyRO: SafeHtml;
	htmlBodyFR: SafeHtml;
  htmlBodyEN: SafeHtml;
  content_text_list: HomepageContentResource[];

  
  constructor(private textService: ContentTextService,private sanitizer: DomSanitizer) { }

  ngOnInit() {
    // this.getContent();
  }

  getContent() {
		this.textService.getContent(`/homepage/getAllActiveHomepageContent`).subscribe(
			body => {
        this.content_text_list = body.homepageContentList;
        for(let i=0; i< this.content_text_list.length;i++){
          this.content_text_list[i].textRO = this.sanitizer.bypassSecurityTrustHtml(<string>this.content_text_list[i].textRO)
          this.content_text_list[i].textFR = this.sanitizer.bypassSecurityTrustHtml(<string>this.content_text_list[i].textFR)
          this.content_text_list[i].textEN = this.sanitizer.bypassSecurityTrustHtml(<string>this.content_text_list[i].textEN)
        }
			},
			error => this.errorMessage = <any>error)
  }
  
  goToContact(ev){
    var currentEvent = document.getElementById("contact");
    window.scrollTo(0, currentEvent.offsetTop);
  }

   goToCMS(ev){
    var currentEvent = document.getElementById("easyDoCMS");
    window.scrollTo(0, currentEvent.offsetTop);
  }

   goToEvents(ev){
    var currentEvent = document.getElementById("eventsSection");
    window.scrollTo(0, currentEvent.offsetTop);
  }

   goToAccess(ev){
    var currentEvent = document.getElementById("edtAccess");
    window.scrollTo(0, currentEvent.offsetTop);
  }

}
