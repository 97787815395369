import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-smart-lab',
  templateUrl: './smart-lab.component.html',
  styleUrls: ['./smart-lab.component.scss']
})
export class SmartLabComponent implements OnInit {

  sectionCont:any;
  imageAndContent: any;
  servicesColumnContent: any;
  imageAndContent2: any;
  sectionContent: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "Smart Lab, Metode Inteligente Pentru Educaţie",
      description:"Easydo sustine scolile care vor sa se finanteze prin Programul de finantare „Dotarea cu laboratoare inteligente a unităților de învățământ secundar superior, a palatelor și a cluburilor copiilor”, parte a pilonului 6 din “Componenta C15 Educație a PNRR (https://mfe.gov.ro/pnrr/) Peste 1100 de laboratoare inteligente vor fi create.",
      bkgImage:"assets/pexels-alena-darmel-7743257.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };
    this.imageAndContent = {
      imageLeft: false,
      imageRight: true,
      img:"assets/boy-thinking-looking-charging-board.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"",
        topContent:"",
        checkMark: false,
        contentList:[
          "Sustine initiativele programelor educationale STIAM",
          "Mareste satisfactia si rezultatele profesorilor pentru predare",
          "Ajuta comunitatile sa creasca",
          "Accelereaza invatarea",
          "Dezvolta competentele de invatare sociala si emotionala"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.servicesColumnContent = {
      title: "Un laborator inteligent include sisteme hardware si software pentru un concept inovativ de educatie:",
      description: "",
      backgroundColor:"#14181E",
      textColor:"#ffffff",
      boxexGridColor:"#2E2E2E",
      boxesGridWidth:"1px",
      boxes: [
        {
          img: "assets/Icon awesome-tablet-alt.png",
          title: "Tablă interactivă"
        },
        {
          img: "assets/Icon ionic-ios-print.png",
          title: "Imprimantă 3D"
        },
       {
          img: "assets/Icon material-scanner.png",
          title: "Scanner 3D"
        },
        {
          img: "assets/robot-arm-svgrepo-com.png",
          title: "Robot cu braț multifuncțional"
        },
        {
          img: "assets/Icon awesome-robot.png",
          title: "Kituri robotice"
        },
        {
          img: "assets/Groupe 281.png",
          title: "Creion 3D"
        },
        {
          img: "assets/Icon awesome-vr-cardboard.png",
          title: "Ochelari de realitate virtuală VR/AR"
        },
        {
          img: "assets/Icon material-video-call.png",
          title: "Sisteme de videoconferință"
        },
        {
          img: "assets/Icon material-surround-sound.png",
          title: "Sisteme sunet"
        },
        {
          img: "assets/Icon material-desktop-windows.png",
          title: "Sisteme PC"
        },
        {
          img: "assets/Icon awesome-tablet-alt.png",
          title: "Cameră de documente"
        },
        {
          img: "assets/Icon simple-codeschool.png",
          title: "Software specific fiecărei filiere"
        },
        {
          img: "assets/Icon metro-lab.svg",
          title: "Laboratoare virtuale VR/AR"
        },
        {
          img: "assets/learning-school-svgrepo-com.svg",
          title: "Platforma e-learning"
        },
        {
          img: "assets/Groupe 278.png",
          title: "Senzori, holograme",
          pathLink:'/smart-lab/hologram'
        },
        {
          img: "assets/Icon ionic-md-school.png",
          title: "Echipamente educaționale specifice"
        },
      ]
    }
    this.imageAndContent2 = {
      imageLeft: false,
      imageRight: true,
      img:"assets/pexels-katerina-holmes-5905445.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"",
        topContent:"",
        checkMark: false,
        contentList:[
          "• Colegii",
          "• Licee",
          "• Licee Tehnologice",
          "• Scoli profesionale, inclusiv cele cu clase de învățământ dual",
          "• Palatele copiilor ",
          "• Cluburile copiillor cu personalitate juridică"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.sectionContent = {
      isDots: false,
      dotsImg: null,
      isImg: true,
      img: "assets/SmartLab.png",
      isVideo: false,
      link: null,
      title: "Easydo un partener de implementare pe termen lung",
      description:"text1"
    }
  }

}
