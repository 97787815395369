import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-managed-services',
  templateUrl: './managed-services.component.html',
  styleUrls: ['./managed-services.component.scss']
})
export class ManagedServicesComponent implements OnInit {

  sectionCont:any;
  imageAndContent:any;
  servicesColumnContent:any;
  sectionContent:any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "Managed Services",
      description:"By automating applications services, infrastructure services and security services, we help you save costs and keep your organization running smoothly through reliable resources provision. Our infrastructure capacity leads to economies of scale and helps you focus your attention and resources on your core business, without having to worry about technology management.",
      bkgImage:"assets/managed_services.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"",
      bkgColor2:"",
      bkgGradient: false,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };

    this.servicesColumnContent = {
      title: "The range of IT services that we can manage for you include: ",
      description: "",
      backgroundColor:"#14181E",
      textColor:"#C2C2C2",
      boxexGridColor:"#2E2E2E",
      boxesGridWidth:"1px",
      boxes: [
        {
          img: "assets/testingqualityassurance.png",
          title: "Testing & Quality Assurance",
          description: "to ensure your audience benefits from the best user experience. "
        },
        {
          img: "assets/settings.png",
          title: "Preventive maintenance",
          description: "we monitor and ensure the seamless functionality of your hardware & workstations on premise or remotely"
        },
       {
          img: "assets/corrective-maintenance.png",
          title: "Corrective maintenance",
          description: "whenever there’s an incident, you notify us, and we fix your problem right away based on a Service Level Agreement. "
        },
        {
          img: "assets/softwareupdates.png",
          title: "Software updates",
          description: "Software updates on your employees’ workstations, through remote access."
        },
        {
          img: "assets/briefcase.png",
          title: "Business Process Outsourcing",
          description: "by outsourcing various business-related operations, you will gain major strategic and financial benefits."
        },
        {
          img: "assets/callcenterservices.png",
          title: "Call Center Services",
          description: "tech support, customer care, sales and market surveys."
        }
      ]
    }

    this.sectionContent={
        isDots: true,
        dotsImg:"assets/dots.png",
        isImg: true,
        img: "assets/bpo2.png",
        isVideo: false,
        link: "",
        title: "BPO KYC",
        description:"The EASYDO KYC (Know-Your-Customer) teams carry out a number of KYC control services, administrative and accounting follow-ups. This service is available 24/7 and in multiple languages. Most of our clients in this area are payment services providers and financial institutions.",
    }

  }

}
