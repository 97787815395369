import { Component, OnInit } from '@angular/core';
import { Input } from "@angular/core";
import { MenuButtonListResource } from "app/models/MenuButtonListResource";
import { MenuButtonResource } from "app/models/MenuButtonResource";
import { MainMenuService } from "app/main-menu/main-menu.service";
import { Router } from "@angular/router";
import { MenuRoutes } from "app/main-menu/menu-routes";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Globals } from "app/globals";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "app/app.service";

@Component({
	selector: 'vbn-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss'],
	providers: [MainMenuService, MenuRoutes]
})
export class MainMenuComponent implements OnInit {

	rootMenu: MenuButtonResource[]
	showSecondlane: boolean
	secondLaneMenu: MenuButtonResource
	secondLaneMenuRes: MenuButtonResource[];
	threeLaneMenuRes: MenuButtonResource[];
	fourLaneMenuRes: MenuButtonResource[];
	currentSelectedRootButton: MenuButtonResource
	params: any
	lvl2BtnCrt: number = 0;
	showMenuResponsive: boolean = false;
	showLeveltree: boolean = false;
	showTheArrow = [];
	showTheArrowLvl = [];
	menuIndex: number;
	menuIndexSecond: number;
	menuIndexThree: number;
	showLevelfour: boolean = false;
	nrAtTheLine: number;
	nrAtTheSLine: number;
	//meniu vertical
	// currentList: any;
	// elementWidth: any;
	color: any;

	constructor(private _router: Router,
		private route: ActivatedRoute,
		private appService: AppService,
		private mainMenuService: MainMenuService,
		private menuRoutes: MenuRoutes) {
		this.showSecondlane = false
		this.secondLaneMenu = new MenuButtonResource();
	}

	ngOnInit() {
		this.getMenu()
	}
	getMenu() {
		this.mainMenuService.getMenu(`/menu/publicMain/get`).subscribe(
			(menu_data: MenuButtonListResource) => {
				this.rootMenu = menu_data.buttons;
				for (let i = 0; i < this.rootMenu.length; i++) {
					this.rootMenu[i].treeLevel = 0;
				}
			},
			error => console.error(<any>error))
	}
	// Pentru meniul de responsive
	getSecondLineMenuRes(item: MenuButtonResource, level: number, index: number) {
		this.menuIndex = index;
		this.lvl2BtnCrt = 0;
		this.goToRes(item, level)
		this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${item.rid}`).subscribe(
			(menu_data: MenuButtonListResource) => {
				if (menu_data.buttons) {
					// this.showSecondlane = true
					this.secondLaneMenuRes = menu_data.buttons
					this.currentSelectedRootButton = item
					for (let i = 0; i < menu_data.buttons.length; i++) {
						if (menu_data.buttons[i].noOfChilds !== 0) {
							this.showTheArrow[i] = true;
							// this.getTrhLineMenuRes(menu_data.buttons[i], 2) 
						} else {
							this.showTheArrow[i] = false;
						}
					}
				} else {
					this.hideSecondlane()
				}
			},
			error => console.error(<any>error))
	}
	getTrhLineMenuRes(item: MenuButtonResource, level: number, index: number) {
		this.menuIndexSecond = index;
		this.lvl2BtnCrt = 0;
		this.goToRes(item, level)
		this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${item.rid}`).subscribe(
			(menu_data: MenuButtonListResource) => {
				if (menu_data.buttons) {
					this.threeLaneMenuRes = menu_data.buttons
					// this.currentSelectedRootButton = item
					for (let i = 0; i < menu_data.buttons.length; i++) {
						if (menu_data.buttons[i].noOfChilds !== 0) {
							this.showTheArrowLvl[i] = true;
						} else {
							this.showTheArrowLvl[i] = false;
						}
					}
				} else {
					this.hideSecondlane()
				}
			},
			error => console.error(<any>error))

	}
	getFourLineMenuRes(item: MenuButtonResource, level: number, index: number) {
		this.menuIndexThree = index;
		this.lvl2BtnCrt = 0;
		this.goToRes(item, level)
		this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${item.rid}`).subscribe(
			(menu_data: MenuButtonListResource) => {
				if (menu_data.buttons) {
					this.fourLaneMenuRes = menu_data.buttons

				} else {
					this.hideSecondlane()
				}
			},
			error => console.error(<any>error))
	}
	goToRes(item: MenuButtonResource, level: number) {
		this.lvl2BtnCrt = item.rid;
		if (item.nodeItemResource != null) {
			if (item.nodeItemResource.contentTemplate != null) {
				//in loc de "bucuresti" trebuie sa ajunga locatia
				this._router.navigate(MenuRoutes.fetch(item))
			}
		} else {
			if (level === 2) {
				this._router.navigate([`/menu_view`, this.currentSelectedRootButton.color, item.nameRO, item.rid])
			}
		}
		if (item.externalButton === true) {

			if (item.link.indexOf("institutfrancais.ro") < 0) {
				window.open(item.link, '_blank')
			} else {
				window.open(item.link, "_self")
			}

		}

	}
	closeResponsive(val) {
		if (val['noOfChilds'] === 0) {
			this.showMenuResponsive = false;
			this.showSecondlane = false
			this.showLeveltree = false;
			this.showLevelfour = false;
		}
	}

	closeResponsiveWhitoutSecondLine(val) {
		if (val['noOfChilds'] === 0) {
			this.showMenuResponsive = false;
			this.showLeveltree = false;
			this.showLevelfour = false;
		}
	}
	closeResponsivePresFirst(index: number) {
		if (this.nrAtTheLine == index) {
			if (this.showSecondlane == true) {
				this.showSecondlane = false;

			} else {
				setTimeout(() => {
					this.showSecondlane = true;
				}, 100);
			}
		} else {
			this.showSecondlane = true;
		}
		this.nrAtTheLine = index;
		this.showLeveltree = false;
	}
	closeResponsivePresTwo(index: number) {

		if (this.nrAtTheSLine == index) {
			if (this.showLeveltree == true) {
				this.showLeveltree = false;

			} else {
				setTimeout(() => {
					this.showLeveltree = true;
				}, 100);
			}
		} else {
			setTimeout(() => {
				this.showLeveltree = true;
			}, 100);
		}
		this.nrAtTheSLine = index;
		this.showLevelfour = false;
	}
	closeResponsivePresThree() {
		if (this.showLevelfour == true) {
			this.showLevelfour = false;
		} else {

			setTimeout(() => {
				this.showLevelfour = true;
			}, 200);
		}
	}
	getSecondLane() {
		this.showSecondlane = true
	}

	hideSecondlane() {
		this.showSecondlane = false
		this.currentSelectedRootButton = undefined
		// this.menuIndex = null;
	}


	goTo(item: MenuButtonResource) {
		this.lvl2BtnCrt = item.rid;
		for (let i = 0; i < this.rootMenu.length; i++) {
			this.rootMenu[i].showSecondlane = false;
		}
		if (item.nodeItemResource != null) {
			if (item.nodeItemResource.contentTemplate != null) {
				this._router.navigate(MenuRoutes.fetch(item))
			}
		}
		if (item.externalButton === true) {
			window.open(item.link, '_blank')
		}
	}



	applyBackground(item: MenuButtonResource) {
		if (this.currentSelectedRootButton != undefined && this.currentSelectedRootButton.rid === item.rid) {
			return {
				'background-color': `#${this.currentSelectedRootButton.color}`,
				'color': '#fff'
			}
		} else {
			return {}
		}
	}

	goHome() {
		this._router.navigate([`/home`])
	}

	// 	meniu vertical
	getSecondLineMenu(item: MenuButtonResource) {
		this.goTo(item)
		this.mainMenuService.getSecondLineMenu(`/menu/getPublicMenu/${item.rid}`).subscribe(
			(menu_data: MenuButtonListResource) => {
				if (menu_data.buttons) {
					this.currentSelectedRootButton = item
					for (let i = 0; i < this.rootMenu.length; i++) {
						this.rootMenu[i].showSecondlane = false;
						if (item.rid == this.rootMenu[i].rid) {
							item.nodes = menu_data.buttons
							item.isClicked = true;
							this.color = this.rootMenu[i].color;
							item.showSecondlane = true
						}
					}
				}
				// else {
				// 	this.hideSecondlane()
				// }
			},
			error => console.error(<any>error))
	}

	toggle(button: MenuButtonResource) {
		if (!button.parrentButtonId) {
			this.getSecondLineMenu(button)
		}
	}

	check(ev) {
		console.log("----", ev);
		if (ev) {
			this.getMenu();
		}
	}
}
