import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-hologram',
  templateUrl: './hologram.component.html',
  styleUrls: ['./hologram.component.scss']
})
export class HologramComponent implements OnInit {
  
  sectionCont: any;
  imageAndContent: any;
  imageAndContent2: any;
  imageAndContent3: any;
  imageAndContent4: any;
  imageAndContent5: any;
  imageAndContent6: any;

  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "Hologram led fan for educationnal and commercial use",
      description:"textBkgHologram",
      bkgImage:"assets/hologram-bkg.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor:"#14181E",
      image:"assets/dots.png",
      bkgColor1:"#2F3D56",
      bkgColor2:"#070A0E",
      bkgGradient: true,
      button:{},
      subtitle:"",
      additionalButtons:[ ]
    };
    this.imageAndContent = {
      imageLeft: true,
      imageRight: false,
      img:"assets/33074003226.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"",
        topContent:"A holographic propeller is a device (like a 3D printer or a screen) that allows you to:",
        checkMark: false,
        contentList:[
          " • Display in the air an animated image or video, in color",
          " • A 3D effect that can make the model very attractive",
          " • Increases student’s creativity and learning skills"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.imageAndContent2 = {
      imageLeft: false,
      imageRight: true,
      img:"assets/boy-thinking-looking-charging-board1.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"Enhanced Visual Learning",
        topContent:"Hologram images provide an enhanced visual experience that can help students :",
        checkMark: false,
        contentList:[
          " • Better understand and remember complex concepts.",
          " • Visualize abstract ideas",
          " • Visualize objects"
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.imageAndContent3 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/F100030775.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"Intercative Learning",
        topContent:"",
        checkMark: false,
        contentList:[
          "Hologram images can also be used to create an interactive learning experience that engages students and encourages them to participate in their own education. This can be especially effective for students who are more visually oriented or who struggle with traditional teaching methods.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.imageAndContent4 = {
      imageLeft: false,
      imageRight: true,
      img:"assets/increased_engagement-image.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"Increased Engagement",
        topContent:"",
        checkMark: false,
        contentList:[
          "The use of hologram images can also increase student engagement in the classroom, as it introduces a level of novelty and excitement to the learning experience. This can be particularly helpful for students who may be disengaged or bored with traditional classroom teaching methods.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.imageAndContent5 = {
      imageLeft: true,
      imageRight: false,
      img:"assets/picography-macbook-wireframe-notebook-sketch.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"Real-Life Applications",
        topContent:"",
        checkMark: false,
        contentList:[
          "Hologram technology is becoming increasingly prevalent in many industries, so exposing students to hologram images in school can help prepare them for the future job market and give them a practical understanding of how this technology is used in various fields.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
    this.imageAndContent6 = {
      imageLeft: false,
      imageRight: true,
      img:"assets/welcome-4737158_1920.png",  //the image from -left side
      content:{   //text content from right side
        title:"", 
        subtitle:"",
        topContent:"",
        checkMark: false,
        contentList:[
          "For example, in a math course, holographic helices can be used to display three-dimensional models of geometry and algebra, which will help students understand abstract math concepts. In a literature course, holographic propellers can be used to display three-dimensional models of characters and scenes in a book, which will help students better understand literary works.",
        ],
        additionalContent:'', //text at the bottom of this content
      }
    }
  }

}
