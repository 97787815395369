/* tslint:disable */
import { MenuButtonResource } from "./MenuButtonResource"

export class MenuButtonListResource {
		errorCode: number;
		errorMessage: string;
		widgets: null;
		buttons: MenuButtonResource[];
		isClicked: boolean = false;
}
