import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'translateArr',
	pure: false
})
export class TranslateJsPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch (localStorage.getItem("vbn-language")) {
			case "ro":
				return value[0]
			case "fr":
				return value[1]
			case "en":
				return value[2]
		}
	}

}
