import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'app/app.service';
import { MessageService } from 'app/message-service.service';
import { Subscription } from 'rxjs/Subscription';
@Component({
  selector: 'vbn-section-description',
  templateUrl: './section-description.component.html',
  styleUrls: ['./section-description.component.scss']
})
export class SectionDescriptionComponent implements OnInit {
  subscription: Subscription;
  sectionContent: any;
  dotsDisplay: boolean;
  fullWidth: boolean;
  show_modal: boolean = false;
  showText: boolean[] = [false, false, false];

  @Input()
  content
  @Input()
  displayDots

  @Input()
  displayFull

  @Input()
  bottomMargin

  constructor(private service: AppService, private messageService: MessageService) { 
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if(message.closePopup) {
        this.show_modal = false;
      }
		});
  }

  ngOnInit() {
    this.sectionContent = this.content;
    this.dotsDisplay = this.displayDots;
    this.fullWidth = this.displayFull;
  }

  displayText(event, indx){
      this.showText = [false, false, false];
      this.showText[indx] = true;
  }
}
