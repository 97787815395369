import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vbn-products2',
  templateUrl: './products2.component.html',
  styleUrls: ['./products2.component.scss']
})
export class Products2Component implements OnInit {
  sectionCont: any;
  columnContent: any;
  sectionList: any;
  constructor() { }

  ngOnInit() {
    this.sectionCont = {
      title: "Splunk",
      description: "As an official partner of Splunk, we share their mission to make machine data accessible across an organization by identifying data patterns, providing metrics, diagnosing problems, and providing intelligence for business operations.",
      bkgImage: "assets/products2.png", //used if we display a bkg image instead of gradient color bkg
      imgOverlayColor: "#14181e",
      image: "assets/dots.png",
      bkgColor1: "#2F3D56",
      bkgColor2: "#070A0E",
      bkgGradient: true,
      button: {},
      subtitle: "",
      additionalButtons: []
    };

    this.columnContent ={
      title:"",
      cols: [
          {
            topImg: "assets/network-svgrepo-com (1)@2x.png",
            title:"Shorter time to benefit",
            description: "Shorter time to benefit – you can use the tools that you need in a matter of hours, without the time spent in installation and configuration.",
            button:{
            },
          },
          {
            topImg: "assets/delivery-truck-svgrepo-com@2x.png",
            title:"Cost savings",
            description:"- in terms of hardware and software licenses, as well as maintenance costs. Additionally, our feature-based packages ensure that you only pay for what you need, as your organization grows.",
            button:{
            },
          },
          {
            topImg: "assets/security-svgrepo-com.png",
            title:"Scalability and integration",
            description:"As your organization’s needs evolve, you do not have to buy another server or software, you can simply enable a new SaaS offering.",
            button:{
            },
          }
        ]
      }

      this.sectionList = {
        title: "Why choose EasyDo as a Splunk Integrator?",
        checkMark: true,
        contentList: [
          "Capacity to deliver consulting services",
          "Highly experienced local team to accompany your project through all phases from definition to implementation, change management and roll out",
          "Architecture, solutions and custom development adapted to the client’s need, taking into account all use cases",
          "An experienced team, with business and technical capabilities enhanced by Splunk, a clear leader in machine data and operational intelligence",
          "Capacity to deliver resources: PM, Architect, Consultants, Trainer",
          "Local support & maintenance in different modes, including 24 x 7, for the roll out",
          "SOC services based on Splunk and other applications",
        ]
      }
  }

}
