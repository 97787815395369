import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate, group, query }from '@angular/animations';

@Component({
  selector: 'vbn-slide-hompage3',
  templateUrl: './slide-hompage3.component.html',
  styleUrls: ['./slide-hompage3.component.scss'],
  animations: [

    trigger('simpleFadeAnimation', [
      transition(':increment', group([
        query(':enter', [
          style({
            opacity: '0',
            
          }),
          animate('1s ease-out', style('*'))
        ]),
      /*   query(':leave', [
          animate('10s ease-out', style({
            transform: 'scale(0.1)',

          }))
        ]), */
      ])) ,
      transition(':decrement', group([
        query(':enter', [
          style({
            opacity: '0',
          }),
          animate('1s ease-out', style('*'))
        ]),
        /* query(':leave', [
          animate('1s ease-out', style({
            opacity: '0',
          }))
        ]) */
      ])) 
    ]),
    trigger('slideAnimation', [
      transition(':increment', group([
        query(':enter', [
          style({
            transform: 'translateX(100%) scale(0.5)',
            
          }),
          animate('1s ease-out', style('*'))
        ]),
        query(':leave', [
          animate('1s ease-out', style({
            transform: 'translateX(-110%) scale(0.5)',

          }))
        ])
      ])) ,
      transition(':decrement', group([
        query(':enter', [
          style({
            transform: 'translateX(-100%) scale(0.5)',
          }),
          animate('1s ease-out', style('*'))
        ]),
        query(':leave', [
          animate('1s ease-out', style({
            transform: 'translateX(110%) scale(0.5)',
           
          }))
        ])
      ])) 
    ])
  ]
/*   animations: [
    trigger('slideAnimation', [
      transition(':enter', [style({ opacity: 0 }),
          animate('4000ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('400ms', style({ opacity: 0 })) ])
    ])
  ] */
})
export class SlideHompage3Component implements OnInit {

 

  slides = [
    { src: '/assets/golesti.png', title: 'Title 1', text2: "Iustin DEJANU / Manager Golesti Museum", text: "It's fantastic to work with EasyDO Digital Technologies team. We have a true partner who cares about our short and long term goals and needs. Moreover, we received the logistical support we needed and many interesting suggestions from the EasyDO team. We appreciate the speed which they have responded to our demands, the ability to understand the complexity of our activities and the creativity which they have responded to the challenges. The Golesti Museum team recommends EasyDO Digital Technologies as a professional and trustworthy partner." },
    { src: 'https://placeimg.com/600/600/tech', title: 'Title 1', text2: "Top 5 IT company / Bucharest, Doamnei Street nr.14-16, District 3", text: "KeyPaaS allowed us to seriously improve our operations (digital agent to manage control access, scrum the organization, e-booking meeting rooms, secure with range of rights the all organization)." },
    { src: 'https://placeimg.com/600/600/tech', title: 'Title 1', text2: "Building Facility Management / Bucharest, Pechea Street nr.13", text: "Each tenant has its own control access settings and its own equipment. With KeyPaaS, we can orchestrate all tenants in a single application. We particularly appreciate the kitchen priority management that helps to organize access workload during the lunch." },
    { src: 'https://placeimg.com/600/600/tech', title: 'Title 1', text2: "Historical Heritage Building enhancement / Sibiu, Turnului Street nr.7", text: "A booking platform allows to manage full time occupation of building apartments. The boarding pass, generated after room reservation is paid, is communicating with the KeyPaaS. KeyPaaS  simplifies daily operations for the employees and increase the guests’ satisfaction." }
  /*   { src: 'https://placeimg.com/600/600/nature', title: 'Title 2', text2: "Office Management for a top 5 IT company | Bucharest, Doamnei Street nr.14-16, District 3", text: "KeyPaaS allows us to seriously improved our operations (digital agent to manage control access, scrum the organization, e- booking meeting rooms, secure with range of rights the all organization)." },
    { src: 'https://placeimg.com/600/600/sepia', title: 'Title 3',text2: "Building Facility Management | Bucharest, Pechea Street nr.13", text: "Each tenant has its own control access settings and its own equipment. With KeyPaaS, we can orchestrate all tenants in a single application. We particularly appreciate the kitchen priority management that helps to organize access workload during the lunch." },
    { src: 'https://placeimg.com/600/600/people', title: 'Title 4',text2: "Historical Heritage Building enhancement | Sibiu, Turnului Street nr.7", text: "A booking platform allows to manage full time occupation of building apartments. The boarding pass, generated after room reservation is paid, is communicating with the KeyPaaS. KeyPaaS  simplifies daily operations for the employees and increase the guests’ satisfaction." }
    { src: 'https://placeimg.com/600/600/tech', title: 'Title 5',text2: "David Coyne | CEO Vauban", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing" }
  */  
  ];
  currentIndex = 0;
  currentIndexRight = 1;
  currentIndexLeft = this.slides.length-1;

  constructor() { }

  ngOnInit() {
  }


setCurrentSlideIndex(index) {
    this.currentIndex = index;
    this.currentIndexLeft = (this.currentIndex > 0 )?(this.currentIndex-1):this.slides.length - 1;
    this.currentIndexRight = (this.currentIndex < this.slides.length - 1)?(this.currentIndex+1):0;
  }
isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }
isCurrentSlideIndexRight(index) {
    return this.currentIndexRight === index;
  }
isCurrentSlideIndexLeft(index) {
    return this.currentIndexLeft === index;
  }
prevSlide() {
    this.currentIndex = (this.currentIndex > 0) ? --this.currentIndex : this.slides.length - 1;
    this.currentIndexLeft = (this.currentIndex > 0 )?(this.currentIndex-1):this.slides.length - 1;
    this.currentIndexRight = (this.currentIndex < this.slides.length - 1)?(this.currentIndex+1):0;

    }
nextSlide() {
    this.currentIndex = (this.currentIndex < this.slides.length - 1) ? ++this.currentIndex : 0;
    this.currentIndexLeft = (this.currentIndex > 0 )?(this.currentIndex-1):this.slides.length - 1;
    this.currentIndexRight = (this.currentIndex < this.slides.length - 1)?(this.currentIndex+1):0;

  }

}
